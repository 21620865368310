import { useEffect, useState, useCallback } from "react";
import { fetchChatConfig } from "../../Services/Https/quickwork";
import { useSnackbar } from "notistack";

const ChatHead = () => {
  const [, setBaseUrl] = useState("");
  const [, setWebsiteToken] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const loadScript = useCallback(
    (url: string, token: string) => {
      const script = document.createElement("script");
      script.src = `${url}/packs/js/sdk.js`;
      script.async = true;

      script.onload = () => {
        if (window.qwcSDK) {
          window.qwcSDK.run({
            websiteToken: token,
            baseUrl: url,
          });
        } else {
          enqueueSnackbar("qwcSDK is not defined after script load", {
            variant: "error",
          });
        }
      };

      script.onerror = () => {
        enqueueSnackbar(`Failed to load script: ${script.src}`, {
          variant: "error",
        });
      };

      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await fetchChatConfig();
        setBaseUrl(config.url);
        setWebsiteToken(config.token);
        loadScript(config.url, config.token);
      } catch (error) {
        enqueueSnackbar(
          "Failed to fetch chat configuration. Please try again.",
          {
            variant: "error",
          }
        );
      }
    };

    fetchConfig();
  }, [enqueueSnackbar, loadScript]);

  return null;
};

export default ChatHead;
