import { WebstiteStatus } from "../../Constants/Enums/companyStatus";

export const statusToStringWebsites = (status: number): string => {
    switch (status) {
      case WebstiteStatus.WAITING:
        return "Waiting for processing";
      case WebstiteStatus.IN_PROGRESS:
        return "Website is being processed";
      case WebstiteStatus.PROCESSED:
        return "Website is processed";
      case WebstiteStatus.ERROR:
        return "Error happened while processing website";
    default:
      return "Unknown Status";
      }
  };
