import { ModelLevel } from "./user";

export interface ModelOption {
  id: ModelID;
  name: string;
}

export enum ModelID {
  GPT_3_5_TURBO = 0,
  GPT_4_TURBO = 1,
  COMMA_SOFT_ALAN = 2,
}

export const availableModels: ModelOption[] = [
  { id: ModelID.GPT_3_5_TURBO, name: "OpenAI GPT-3-Turbo [v1106]" },
  { id: ModelID.GPT_4_TURBO, name: "OpenAI GPT-4-Turbo [v1106-preview]" },
  { id: ModelID.COMMA_SOFT_ALAN, name: "Comma Soft Alan [coming soon]" },
];

export const model_access_map: { [key in ModelLevel]: ModelID[] } = {
  [ModelLevel.BASIC]: [ModelID.GPT_3_5_TURBO],
  [ModelLevel.ENTRY]: [
    ModelID.GPT_3_5_TURBO,
    ModelID.COMMA_SOFT_ALAN,
  ],
  [ModelLevel.FULL]: [
    ModelID.GPT_3_5_TURBO,
    ModelID.GPT_4_TURBO,
    ModelID.COMMA_SOFT_ALAN,
  ],
};
