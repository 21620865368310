import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Box,
} from "@mui/material";
import { CompanyInfo } from "../../Models/company";
import {
  getSingleCompany,
  deleteSingleCompany,
  updateSingleCompany,
  updateAboutCompany,
} from "../../Services/Https/company";
import { AxiosResponse } from "axios";
import CircleIcon from "@mui/icons-material/Circle";
import { enqueueSnackbar } from "notistack";
import Evaluation from "../Evaluation/Evaluation";
import ErrorIcon from "@mui/icons-material/Error";
import FileUploadModal from "../FileUpload/FileUploadModal";
import { CompanyStatus } from "../../Constants/Enums/companyStatus";
import color from "../../Constants/colors";
import { EvaluationResponse } from "../../Models/evaluation";
import CompanyActions from "./CompanyActions";
import { useReviewQuestions } from "../../Hooks/useReviewQuestions";
import { areObjectsEqual } from "../../helpers/helpers";
import { handleAxiosError } from "../../Services/Https/errorHandler";

const SingleCompanyComponent: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null);
  const [originalCompanyInfo, setOriginalCompanyInfo] =
    useState<CompanyInfo | null>(null);
  const [resetAnswers, setResetAnswers] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [disablingEdit, setDisablingEdit] = useState<boolean>(true);
  const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [visibility, setVisibility] = useState<"visible" | "hidden">("visible");
  const evaluationRef = useRef<HTMLDivElement>(null);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const [responseName, setResponseName] = useState<string>("");
  const [responseAbout, setResponseAbout] = useState<string>("");
  const [responseIndustry, setResponseIndustry] = useState<string>("");
  const [responseLocation, setResponseLocation] = useState<string>("");
  const [responseAnswers, setResponseAnswers] = useState<{
    [key: string]: string;
  }>({ "": "" });
  const [evaluationInProgress, setEvaluationInProgress] = useState<
    boolean | null
  >(null);

  const questionListId =
    companyInfo?.last_evaluation_request?.question_list?.id ?? 0;

  const certificationId = 0;

  const centerAltReturnsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  };

  const {
    answers,
    handleAnswerChange,
    handleFileChange,
    setAnswers,
    questionList,
    handleSubmitUpdateAboutCompany,
  } = useReviewQuestions(
    questionListId,
    certificationId,
    String(companyInfo?.id),
    companyInfo?.answers ?? ""
  );

  const fetchCompanyInfo = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const data: CompanyInfo = await getSingleCompany(id);
      let review = data.review;
      setEvaluationInProgress(
        data.status === CompanyStatus.EVALUATION_IN_PROGRESS ||
          data.status === CompanyStatus.FETCHING_COMPANY_DETAILS ||
          data.status === CompanyStatus.PROCESSING_COMPANY_INFORMATIONS ||
          data.status === CompanyStatus.GRADING_COMPANY
      );
      if (typeof review === "string") {
        try {
          review = JSON.parse(review);
        } catch (error) {
          setError("Error parsing company review information");
          review = null;
        }
      }
      setCompanyInfo({
        ...data,
        review: review,
      });
      setOriginalCompanyInfo({ ...data, review: review });
      setResponseName(data.name);
      setResponseAbout(data.about);
      setResponseIndustry(data.industry);
      setResponseLocation(data.location);
      setResponseAnswers(JSON.parse(data.answers || "{}"));
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error fetching company: ${errorMessage}`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchCompanyInfo(companyId);
    } else {
      setError("No company ID provided");
      setLoading(false);
    }
  }, [companyId, fetchCompanyInfo]);

  const handleCancel = () => {
    if (originalCompanyInfo) {
      setCompanyInfo({ ...originalCompanyInfo });
      setResponseName(originalCompanyInfo.name);
      setResponseAbout(originalCompanyInfo.about);
      setResponseIndustry(originalCompanyInfo.industry);
      setResponseLocation(originalCompanyInfo.location);
      setAnswers(JSON.parse(originalCompanyInfo.answers));
      setIsSaveButtonEnabled(false);
      setResetAnswers(true);
      setDisablingEdit(!disablingEdit);
    }
  };

  useEffect(() => {
    if (resetAnswers) {
      setResetAnswers(false);
    }
  }, [resetAnswers]);

  if (loading) {
    return (
      <Box sx={centerAltReturnsStyle}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Box sx={centerAltReturnsStyle}>Error: {error}</Box>;
  }
  if (!companyInfo) {
    return (
      <Box sx={centerAltReturnsStyle}>
        <div>No company information found</div>
      </Box>
    );
  }

  const handleSave = async (id: string) => {
    setLoading(true);
    try {
      const { name, about, industry, location } = companyInfo;

      if (name.trim() === "") {
        enqueueSnackbar('"Name" field cannot be empty.', {
          variant: "warning",
        });
        setLoading(false);
        return;
      }

      if (about.trim() === "") {
        enqueueSnackbar('"About" field cannot be empty.', {
          variant: "warning",
        });
        setLoading(false);
        return;
      }

      const checkAnswers = areObjectsEqual(responseAnswers, answers);
      const isCompanyInfoChanged =
        about === responseAbout &&
        name === responseName &&
        industry === responseIndustry &&
        location === responseLocation;

      if (isCompanyInfoChanged && checkAnswers) {
        enqueueSnackbar("No changes detected", { variant: "info" });
      } else {
        if (!isCompanyInfoChanged) {
          await updateSingleCompany(id, name, about, location, industry);
          enqueueSnackbar("Company Updated Successfully", {
            variant: "success",
          });
        }

        if (companyId && answers !== null && !checkAnswers) {
          await updateAboutCompany(companyId, JSON.stringify(answers));
          enqueueSnackbar("Company Updated Successfully", {
            variant: "success",
          });
        }
      }
    } catch (err) {
      setError("Failed to update company");
      enqueueSnackbar("Failed to Update Company", { variant: "error" });
    } finally {
      setLoading(false);
      setDisablingEdit(true);
      setVisibility("visible");
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      if (companyId !== undefined) {
        const response: AxiosResponse<CompanyInfo> = await deleteSingleCompany(
          companyId
        );
        setCompanyInfo(response.data);
        enqueueSnackbar("Deleted Company Successfully", { variant: "success" });
        navigate("/");
      }
    } catch (err) {
      setError("Failed to delete company");
      enqueueSnackbar("Didn't Delete Company Successfully", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    setDisablingEdit(!disablingEdit);
  };

  const handleScrollToComponent = () => {
    evaluationRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (!companyId) {
    return <Grid>Invalid address</Grid>;
  }

  const handleOpenFileUploadModal = () => {
    setFileUploadModalOpen(true);
  };

  const handleCloseFileUploadModal = () => {
    setFileUploadModalOpen(false);
  };

  const handleFieldChange = () => {
    setIsSaveButtonEnabled(true);
  };

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          padding={"0 16px"}
          sx={{
            flexDirection: { xs: "column-reverse", md: "row" },
            marginBottom: 2,
            alignItems: { xs: "space-between", md: "center" },
          }}
        >
          <Grid item>
            <TextField
              value={companyInfo.name}
              label="Name of company"
              disabled={disablingEdit}
              onChange={(e) => {
                setCompanyInfo({ ...companyInfo, name: e.target.value });
                handleFieldChange();
              }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {companyInfo?.status ===
                      CompanyStatus.WAITING_FOR_EVALUATION ||
                    companyInfo?.status === CompanyStatus.NEEDS_MORE_INFO ||
                    companyInfo?.status === CompanyStatus.EVALUATED ? (
                      <CircleIcon
                        sx={{
                          color:
                            companyInfo?.status ===
                            CompanyStatus.WAITING_FOR_EVALUATION
                              ? color.green
                              : companyInfo?.status ===
                                CompanyStatus.NEEDS_MORE_INFO
                              ? color.yellow
                              : color.red,
                        }}
                      />
                    ) : null}
                    {companyInfo?.status === CompanyStatus.ERROR && (
                      <ErrorIcon />
                    )}
                    {(companyInfo?.status ===
                      CompanyStatus.EVALUATION_IN_PROGRESS ||
                      companyInfo?.status ===
                        CompanyStatus.FETCHING_COMPANY_DETAILS ||
                      companyInfo?.status ===
                        CompanyStatus.PROCESSING_COMPANY_INFORMATIONS ||
                      companyInfo?.status ===
                        CompanyStatus.GRADING_COMPANY) && (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    )}
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: disablingEdit
                    ? "rgba(0, 0, 0, 0.2)"
                    : "inherit",
                },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
            />
          </Grid>
          {!evaluationInProgress && (
            <CompanyActions
              companyInfo={companyInfo}
              companyId={companyId}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              onScrollToComponent={handleScrollToComponent}
              onOpenFileUploadModal={handleOpenFileUploadModal}
              disablingEdit={disablingEdit}
              visibility={visibility}
            />
          )}
        </Grid>
        <Grid item padding={"0 16px"}>
          <TextField
            value={companyInfo.industry}
            label="Industry"
            disabled={disablingEdit}
            onChange={(e) => {
              setCompanyInfo({ ...companyInfo, industry: e.target.value });
              handleFieldChange();
            }}
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                backgroundColor: disablingEdit
                  ? "rgba(0, 0, 0, 0.2)"
                  : "inherit",
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
              shrink: true,
            }}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid item padding={"0 16px"}>
          <TextField
            value={companyInfo.location}
            label="Location"
            disabled={disablingEdit}
            onChange={(e) => {
              setCompanyInfo({ ...companyInfo, location: e.target.value });
              handleFieldChange();
            }}
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                backgroundColor: disablingEdit
                  ? "rgba(0, 0, 0, 0.2)"
                  : "inherit",
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
              shrink: true,
            }}
          />
        </Grid>
        <Grid item padding={"0 16px"}>
          <TextField
            label="About"
            multiline
            fullWidth
            value={companyInfo.about}
            disabled={disablingEdit}
            onChange={(e) => {
              setCompanyInfo({ ...companyInfo, about: e.target.value });
              handleFieldChange();
            }}
            variant="outlined"
            margin="normal"
            InputProps={{
              sx: {
                color: disablingEdit ? "white" : "inherit",
                backgroundColor: disablingEdit
                  ? "rgba(0, 0, 0, 0.2)"
                  : "inherit",
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid ref={evaluationRef} mb={5}>
              {companyInfo.review !== null && (
                <Evaluation
                  evaluationResponse={companyInfo.review as EvaluationResponse}
                  questionList={questionList}
                  answers={answers || {}}
                  handleAnswerChange={handleAnswerChange}
                  handleFileChange={handleFileChange}
                  handleSubmitUpdateAboutCompany={
                    handleSubmitUpdateAboutCompany
                  }
                  resetAnswers={resetAnswers}
                  isQuestionFieldDisabled={disablingEdit}
                  onAnswerChange={handleFieldChange}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        {isFileUploadModalOpen && (
          <FileUploadModal
            open={isFileUploadModalOpen}
            onClose={handleCloseFileUploadModal}
            documents={companyInfo.documents}
          />
        )}
      </Container>
      {disablingEdit === false && (
        <Grid
          item
          xs={12}
          mt={5}
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            backgroundColor: color.secondaryDark,
            padding: "1rem",
            zIndex: 1000,
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
        >
          <div>
            <Button
              variant="contained"
              disabled={!isSaveButtonEnabled}
              onClick={() => {
                handleSave(companyId);
              }}
              sx={{
                backgroundColor: color.btn_dark,
                color: "white",
                "&:hover": {
                  backgroundColor: color.primary_dark,
                  color: color.btn_dark,
                },
              }}
            >
              Save Company
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={handleCancel}
              sx={{
                backgroundColor: color.secondary,
                color: "white",
                "&:hover": {
                  backgroundColor: color.secondary,
                },
              }}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
};

export default SingleCompanyComponent;
