import { ChangeEvent, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import color from "../../Constants/colors";
import CloseIcon from "@mui/icons-material/Close";
import QuestionsForm from "./QuestionsForm";

const CategoryForm = ({
  isEdit,
  questionListFull,
  setQuestionListFull,
}: any) => {
  const initialCategories = isEdit ? questionListFull.categories : [];
  const [categories, setCategories] = useState<any>(initialCategories);

  const [editingCategory, setEditingCategory] = useState<string | null>(null);
  const [isCategoryCreated, setIsCategoryCreated] = useState<boolean>(false);
  const [enableButtonsOnChange, setEnableButtonsOnChange] =
    useState<boolean>(true);

  useEffect(() => {
    if (isEdit) {
      setCategories(questionListFull.categories);
    }
  }, [isEdit, questionListFull.categories]);

  const handleCategoryChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number
  ) => {
    const newCategories = categories.map((category: any) =>
      category.id === id
        ? { ...category, category_name: event.target.value }
        : category
    );
    setCategories(newCategories);
    setEnableButtonsOnChange(true);
  };

  const handleBlur = (id: string) => {
    setCategories((prevCategories: any) =>
      prevCategories.map((category: any) =>
        category.id === id ? { ...category, multiline: true } : category
      )
    );
  };

  const handleSubmit = (id: any) => {
    const categoryToSubmit = categories.find((cat: any) => cat.id === id);
    if (categoryToSubmit && categoryToSubmit.category_name.trim() !== "") {
      setQuestionListFull((prevState: any) => {
        // Filter out the initial empty category if it still exists and hasn't been edited
        const filteredCategories = prevState.categories.filter(
          (cat: any) =>
            !(cat.category_name === "" && cat.questions.length === 0)
        );

        // Determine if the category being submitted is new or existing
        const isNewCategory = !filteredCategories.some(
          (cat: any) => cat.id === id
        );

        // Update existing category or add new one
        const updatedCategories = filteredCategories.map(
          (existingCategory: any) =>
            existingCategory.id === id ? categoryToSubmit : existingCategory
        );

        if (isNewCategory) {
          updatedCategories.push(categoryToSubmit);
        }

        return { ...prevState, categories: updatedCategories };
      });

      setEditingCategory(null);
      setIsCategoryCreated(true);
      setEnableButtonsOnChange(false);
    }
  };

  const handleEnableAdding = () => {
    const newCategory = {
      id: uuidv4(),
      category_name: "",
      questions: [],
    };
    setCategories([...categories, newCategory]);
    setEditingCategory(newCategory.id);
    setIsCategoryCreated(false);
  };

  const removeCategoryBlock = (id: number) => {
    // Remove category from local state
    const newCategories = categories.filter(
      (category: any) => category.id !== id
    );
    setCategories(newCategories);

    // Remove category from questionListFull state
    const updatedQuestionListFull = {
      ...questionListFull,
      categories: questionListFull.categories.filter(
        (category: any) => category.id !== id
      ),
    };
    setQuestionListFull(updatedQuestionListFull);
  };

  return (
    <Grid>
      <form>
        {categories.map((category: any) => (
          <Grid
            item
            key={category.id}
            sx={{
              border: 1,
              borderColor: color.orange,
              padding: 5,
              borderRadius: 1,
            }}
            marginBottom={2}
          >
            <TextField
              label="Add Category"
              name="category"
              key={`add-category-${category.id}`}
              value={category.category_name}
              onChange={(e) => handleCategoryChange(e, category.id)}
              onBlur={() => handleBlur(category.id)}
              fullWidth
              sx={{ marginBottom: 2 }}
              multiline={category.multiline}
              disabled={isCategoryCreated && !isEdit}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => removeCategoryBlock(category.id)}>
                    <CloseIcon fontSize="large" sx={{ color: color.red }} />
                  </IconButton>
                ),
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
            />
            {editingCategory === category.id ? (
              <Grid item key={`edit-buttons-${category.id}`}>
                <IconButton onClick={() => handleSubmit(category.id)}>
                  <CheckIcon fontSize="large" sx={{ color: color.orange }} />
                </IconButton>
                <IconButton onClick={() => removeCategoryBlock(category.id)}>
                  <CloseIcon fontSize="large" sx={{ color: color.red }} />
                </IconButton>
              </Grid>
            ) : (
              <>
                {isEdit && enableButtonsOnChange && (
                  <Grid item key={`action-buttons-${category.id}`}>
                    <IconButton onClick={() => handleSubmit(category.id)}>
                      <CheckIcon
                        fontSize="large"
                        sx={{ color: color.orange }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => removeCategoryBlock(category.id)}
                    >
                      <CloseIcon fontSize="large" sx={{ color: color.red }} />
                    </IconButton>
                  </Grid>
                )}
                <QuestionsForm
                  isEdit={isEdit}
                  categoryId={category.id}
                  existingQuestions={category.questions}
                  questionListFull={questionListFull}
                  setQuestionListFull={setQuestionListFull}
                />
              </>
            )}
          </Grid>
        ))}

        <Button
          onClick={handleEnableAdding}
          variant="contained"
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Add new category
        </Button>
      </form>
    </Grid>
  );
};

export default CategoryForm;
