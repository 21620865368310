import React, { useCallback, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TablePagination,
  Grid,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { AxiosError } from "axios";
import FileList from "./FileList";
import { enqueueSnackbar } from "notistack";
import {
  deleteDocument,
  downloadDocument,
  downloadScrappedDocumentContent,
  uploadFile,
} from "../../Services/Https/company";
import { useParams } from "react-router-dom";
import { Document } from "../../Models/company";
import UploadedFiles from "./UploadedFiles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import color from "../../Constants/colors";
import { useDocuments } from "../../Hooks/useCompanies";
import { useDropzone } from "react-dropzone";
import DownloadIcon from "@mui/icons-material/Download";
import { ProcessingStatus } from "../../Models/user";
import CloseIcon from "@mui/icons-material/Close";

interface FileUploadModalProps {
  open: boolean;
  onClose: () => void;
  documents: Document[];
}

const isAxiosError = (error: any): error is AxiosError => {
  return (error as AxiosError).response !== undefined;
};

export const handleSubmitFiles = async (
  companyId: string,
  files: File[],
  onFinish: (name: string, id: number) => void,
  setIsUploadingFiles: React.Dispatch<React.SetStateAction<boolean>>,
  refetchDocuments: any
) => {
  setIsUploadingFiles(true);

  for (const file of files) {
    const formData = new FormData();
    formData.append("company_id", companyId);
    formData.append("document_name", file.name);
    formData.append("file", file);

    await upload(formData, onFinish);
  }

  refetchDocuments();
  setIsUploadingFiles(false);
};

const upload = async (
  formData: FormData,
  onFinish: (name: string, id: number) => void
) => {
  try {
    const response = await uploadFile(formData);
    const documentId = response.data.document_id;
    enqueueSnackbar("Successfully uploaded file", { variant: "success" });
    onFinish(formData.get("document_name") as string, documentId);
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 409) {
      enqueueSnackbar("File is already uploaded", { variant: "error" });
    } else {
      enqueueSnackbar("Failed to upload file", { variant: "error" });
    }
  }
};

function getSubstringAfterLastPeriod(input: string): string {
  const lastIndex = input.lastIndexOf(".");

  if (lastIndex === -1) {
    return "";
  }

  return input.substring(lastIndex + 1);
}

export enum SortBy {
  Name = "name",
  Date = "date",
  Type = "type",
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({ open, onClose }) => {
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [uploadedFilesPage, setUploadedFilesPage] = useState(0);
  const [uploadedFilesRowsPerPage, setUploadedFilesRowsPerPage] = useState(5);
  const { companyId } = useParams<{ companyId: string }>();
  const [soryBy, setSortBy] = useState<SortBy>(SortBy.Name);
  const [fileNames, setFileNames] = useState({});
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);
  const { data: documentsData, refetch: refetchDocuments } = useDocuments(
    companyId!,
    uploadedFilesPage + 1,
    uploadedFilesRowsPerPage,
    soryBy.toString(),
    sortOrder.toString()
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingText, setIsDownloadingText] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleFinishUpload = (name: string, id: number) => {
    setFileNames((prevFileNames) => ({
      ...prevFileNames,
      [id]: name,
    }));
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== name)
    );
  };

  const handleFileChange = useCallback(
    (files: File[]) => {
      if (files) {
        const filesArray = Array.from(files).filter((newFile) => {
          const parts = newFile.name.split(".");
          const fileExtension =
            parts.length > 1 ? parts[parts.length - 1].toLowerCase() : null;
          const allowedExtensions = [
            "doc",
            "docx",
            "pdf",
            "png",
            "jpg",
            "jpeg",
            "txt",
            "ppt",
            "pptx",
            "mp3",
            "wav",
            "aac",
            "ogg",
            "aiff",
            "wma",
          ];
          const isAllowedFileType = fileExtension
            ? allowedExtensions.includes(fileExtension)
            : false;
          const isDuplicate = uploadedFiles.some(
            (existingFile) =>
              existingFile.name === newFile.name &&
              existingFile.size === newFile.size
          );
          return isAllowedFileType && !isDuplicate;
        });

        if (filesArray.length !== files.length) {
          alert(
            "Duplicate files or files with unsupported formats were not added."
          );
        }

        setUploadedFiles((prevFiles) => [...prevFiles, ...filesArray]);
      }
    },
    [uploadedFiles]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleFileChange(acceptedFiles);
    },
    [handleFileChange]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveFile = (fileName: string) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const handleOpenFile = (file: File) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleDeleteDocument = async (documentId: number) => {
    setIsDeleting(true);
    try {
      await deleteDocument(documentId);
      enqueueSnackbar("Document deleted successfully", { variant: "success" });

      if (documentsData) {
        const remainingDocuments = documentsData.documents.length - 1;
        const newPage =
          uploadedFilesPage > 0 &&
          remainingDocuments % uploadedFilesRowsPerPage === 0
            ? uploadedFilesPage - 1
            : uploadedFilesPage;

        setUploadedFilesPage(newPage);
      }

      refetchDocuments();
    } catch (error) {
      enqueueSnackbar("Failed to delete document. Please try again.", {
        variant: "error",
      });
    } finally {
      setTimeout(() => {
        setIsDeleting(false);
      }, 500);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clearAllFiles = () => {
    setUploadedFiles([]);
    setPage(0);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", md: "65%" },
          height: "80vh",
          overflow: "hidden",
          overflowY: "auto",
          backgroundImage: "linear-gradient(#032e4d, #B1B1B3)",
          p: 4,
          borderRadius: 2,
        }}
      >
        <Grid container display="flex" direction="column">
          <Typography
            id="file-upload-modal-title"
            variant="h3"
            sx={{ mb: 2, color: color.white }}
          >
            Upload New Documents
          </Typography>
          <Box
            {...getRootProps()}
            onClick={() =>
              document.getElementById("hidden-file-input")?.click()
            }
            sx={{
              minHeight: "200px",
              border: "2px dashed grey",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: uploadedFiles.length === 0 ? "center" : "start",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            {uploadedFiles.length !== 0 && (
              <Box sx={{ flex: 1, overflowY: "auto" }}>
                <FileList
                  files={uploadedFiles.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  onRemoveFile={handleRemoveFile}
                  onOpenFile={handleOpenFile}
                />
              </Box>
            )}
            <input {...getInputProps()} id="hidden-file-input" />

            {uploadedFiles.length === 0 && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: "100%", width: { xs: "85%", md: "100%" } }}
              >
                <Typography variant="body1" sx={{ color: color.white }}>
                  Drag and drop some files here or click to upload
                </Typography>
                <Tooltip
                  title=".doc, .docx, .pdf, .png, .jpg, .jpeg, .txt,
                  .ppt, .pptx, .mp3, .wav, .aac, .ogg, .aiff, .wma"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: color.secondaryDark,
                      fontStyle: "italic",
                      opacity: 0.6,
                      marginTop: 2,
                    }}
                  >
                    Allowed types: .docx, .pdf, .png, .txt, .pptx, .mp3 ...
                  </Typography>
                </Tooltip>
              </Grid>
            )}
          </Box>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Grid item>
              <TablePagination
                component="div"
                count={uploadedFiles.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `Showing ${from}-${to} of ${count} files`
                }
              />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={5}
              mb={4}
            >
              <Grid item>
                <Tooltip
                  title="No files to clear"
                  disableHoverListener={uploadedFiles.length > 0}
                >
                  <span>
                    <Button
                      sx={{
                        backgroundColor: color.btn_dark,
                        color: color.white,
                        "&:hover": {
                          backgroundColor: color.primary_dark,
                          color: color.btn_dark,
                        },
                      }}
                      disabled={isUploadingFiles || uploadedFiles.length === 0}
                      variant="outlined"
                      onClick={clearAllFiles}
                    >
                      Clear
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip
                  title={uploadedFiles.length === 0 ? "No files to submit" : ""}
                  disableHoverListener={uploadedFiles.length > 0}
                >
                  <span>
                    <Button
                      sx={{
                        backgroundColor: color.btn_dark,
                        color: color.white,
                        "&:hover": {
                          backgroundColor: color.primary_dark,
                          color: color.btn_dark,
                        },
                      }}
                      disabled={isUploadingFiles || uploadedFiles.length === 0}
                      variant="outlined"
                      onClick={async (event) => {
                        event.preventDefault();
                        handleSubmitFiles(
                          companyId!,
                          uploadedFiles,
                          handleFinishUpload,
                          setIsUploadingFiles,
                          refetchDocuments
                        );
                      }}
                    >
                      Submit
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ backgroundColor: color.white }} />
          <Grid container direction="column" mt={2}>
            <Typography
              id="file-upload-modal-title"
              variant="h3"
              sx={{ mb: 2, color: color.white }}
            >
              Company Documents
            </Typography>
            <Grid item mt={2}>
              <Grid
                container
                justifyContent="start"
                spacing={2}
                sx={{ flexGrow: 1, mb: 2 }}
                height="fit-content"
              >
                {documentsData &&
                  documentsData.documents.map((file, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.4}
                      key={file.name}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <React.Fragment>
                        <Box sx={{ position: "relative" }}>
                          {file.status === ProcessingStatus.ERROR && (
                            <CloseIcon
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 2,
                                opacity: 0.8,
                                color: color.red,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                              marginBottom: 1,
                            }}
                          >
                            <Tooltip
                              title={
                                file.status === ProcessingStatus.ERROR ||
                                file.status === ProcessingStatus.IN_PROGRESS
                                  ? ""
                                  : "Download document"
                              }
                            >
                              <span>
                                <IconButton
                                  size="small"
                                  sx={{
                                    color: "orange",
                                    zIndex: 1,
                                    opacity:
                                      file.status === ProcessingStatus.ERROR
                                        ? 0.5
                                        : 1,
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    downloadDocument(
                                      file.id.toString(),
                                      getSubstringAfterLastPeriod(file.name),
                                      setIsDownloading
                                    );
                                  }}
                                  disabled={
                                    isDownloading ||
                                    file.status ===
                                      ProcessingStatus.IN_PROGRESS ||
                                    file.status === ProcessingStatus.ERROR
                                  }
                                >
                                  <CloudDownloadIcon fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip
                              title={
                                file.status === ProcessingStatus.ERROR ||
                                file.status === ProcessingStatus.IN_PROGRESS
                                  ? ""
                                  : "Download document as text file"
                              }
                            >
                              <span>
                                <IconButton
                                  size="small"
                                  sx={{
                                    color: "orange",
                                    zIndex: 1,
                                    opacity:
                                      file.status === ProcessingStatus.ERROR
                                        ? 0.5
                                        : 1,
                                  }}
                                  onClick={() =>
                                    downloadScrappedDocumentContent(
                                      file.id,
                                      fileNames,
                                      setIsDownloadingText
                                    )
                                  }
                                  disabled={
                                    isDownloadingText ||
                                    file.status ===
                                      ProcessingStatus.IN_PROGRESS ||
                                    file.status === ProcessingStatus.ERROR
                                  }
                                >
                                  <DownloadIcon fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip
                              title={
                                file.status === ProcessingStatus.ERROR ||
                                file.status === ProcessingStatus.IN_PROGRESS
                                  ? ""
                                  : "Delete document"
                              }
                            >
                              <span>
                                <IconButton
                                  size="small"
                                  sx={{
                                    color: color.red,
                                    zIndex: 1,
                                    opacity: isDeleting ? 0.5 : 1,
                                    transition: "opacity 0.3s ease",
                                    "&.Mui-disabled": {
                                      color: color.red,
                                      opacity: 0.5,
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteDocument(file.id);
                                  }}
                                  disabled={
                                    isDeleting ||
                                    file.status === ProcessingStatus.IN_PROGRESS
                                  }
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "100%",
                              zIndex: 1,
                              opacity:
                                file.status === ProcessingStatus.ERROR
                                  ? 0.5
                                  : 1,
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                textAlign: "center",
                                wordWrap: "break-word",
                                overflow: "hidden",
                                whiteSpace: "normal",
                              }}
                            >
                              <UploadedFiles file={file} />
                            </Box>
                          </Box>
                        </Box>
                      </React.Fragment>
                    </Grid>
                  ))}
              </Grid>
            </Grid>

            {documentsData && (
              <Grid container direction="row" justifyContent="space-between">
                <Grid container item direction="row">
                  <Select
                    label="Sort By"
                    value={soryBy}
                    onChange={(event) => {
                      setSortBy(event.target.value as SortBy);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: color.primary_dark,
                          opacity: 0.95,
                        },
                      },
                    }}
                  >
                    <MenuItem value={SortBy.Name}>
                      <Typography variant="body1" sx={{ color: color.white }}>
                        Name
                      </Typography>
                    </MenuItem>
                    <MenuItem value={SortBy.Date}>
                      <Typography variant="body1" sx={{ color: color.white }}>
                        Date
                      </Typography>
                    </MenuItem>
                    <MenuItem value={SortBy.Type}>
                      <Typography variant="body1" sx={{ color: color.white }}>
                        Type
                      </Typography>
                    </MenuItem>
                  </Select>
                  <Select
                    label="Sort Order"
                    value={sortOrder}
                    onChange={(event) => {
                      setSortOrder(event.target.value as SortOrder);
                    }}
                    sx={{ ml: 2 }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: color.primary_dark,
                          opacity: 0.95,
                        },
                      },
                    }}
                  >
                    <MenuItem value={SortOrder.Asc}>
                      <Typography variant="body1" sx={{ color: color.white }}>
                        Ascending
                      </Typography>
                    </MenuItem>
                    <MenuItem value={SortOrder.Desc}>
                      <Typography variant="body1" sx={{ color: color.white }}>
                        Descending
                      </Typography>
                    </MenuItem>
                  </Select>
                </Grid>
                <TablePagination
                  component="div"
                  count={documentsData ? documentsData.total : 0}
                  page={Math.max(0, uploadedFilesPage)}
                  onPageChange={(event, newPage) => {
                    setUploadedFilesPage(newPage);
                  }}
                  rowsPerPage={uploadedFilesRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  onRowsPerPageChange={(event) => {
                    setUploadedFilesRowsPerPage(
                      parseInt(event.target.value, 10)
                    );
                    setUploadedFilesPage(0);
                  }}
                  labelDisplayedRows={({ from, to, count }) =>
                    `Showing ${from}-${to} of ${count} files`
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FileUploadModal;
