import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/Home/Home";
import LoginPage from "./Pages/Login";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import withAuth from "./Components/WithAuth/WithAuth";
import Dashboard from "./Pages/Dashboard/Dashboard";
import SingleCompanyComponent from "./Components/SingleCompanyComponent/SingleCompanyComponent";
import CompaniesPage from "./Pages/Companies";
import CreateCompanyPage from "./Pages/CreateCompany/CreateCompanyPage";
import UsersPage from "./Pages/Users/UsersPage";
import CreateUserPage from "./Pages/Users/CreateUserPage";
import UserProfilePage from "./Pages/Users/UserProfilePage";
import QuestionListPage from "./Pages/CreateQuestionsList/CreateQuestionsListPage";
import MyQuestionsPage from "./Pages/MyQuestions/MyQuestionsPage";
import AddNewCategory from "./Components/CreateQuestionsList/AddNewCategory";
import SingleQuestionListPage from "./Pages/SingleQuestionList/SingleQuestionListPage";
import NewHomePage from "./Pages/NewHomePage/NewHomePage";
import DigitalPerformancePage from "./Pages/DigitalPerformancePage/DigitalPerformancePage";
import ExpertFormPage from "./Pages/ExpertFormPage/ExpertFormPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={withAuth(Dashboard, "/login", false)}>
        <Route path="" exact element={<NewHomePage />} />
        <Route path="evaluation" exact element={<HomePage />} />
        <Route path="company/:companyId" element={<SingleCompanyComponent />} />
        <Route path="company" element={<CompaniesPage />} />
        <Route path="/company/create" element={<CreateCompanyPage />} />
        <Route path="/cert_category" element={<QuestionListPage />} />
        <Route path="/cert_category/id" element={<AddNewCategory />} />
        <Route path="/question_list" element={<MyQuestionsPage />} />
        <Route
          path="/question_list/:listId"
          element={<SingleQuestionListPage />}
        />
        <Route path="/expert-form" element={<ExpertFormPage />} />
        <Route
          path="/digital-performance"
          element={<DigitalPerformancePage />}
        />
      </Route>
      <Route path="/" element={withAuth(Dashboard, "/login", true)}>
        <Route path="users" element={<UsersPage />} />
        <Route path="/users/create" element={<CreateUserPage />} />
        <Route path="/user/:userId" element={<UserProfilePage />} />
      </Route>

      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
