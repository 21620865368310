import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Assessment, Home } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FC, useState } from "react";
import { UserRole } from "../../Models/user";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import color from "../../Constants/colors";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import AddIcon from "@mui/icons-material/Add";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import SubjectIcon from "@mui/icons-material/Subject";
import FormIcon from "@mui/icons-material/Description";
import PerformanceIcon from "@mui/icons-material/Speed";

interface Props {
  path: string;
  text: string;
  icon: any;
  disabled?: boolean;
}

export const DashboardMenuItem: FC<Props> = ({
  path,
  text,
  icon,
  disabled,
}) => {
  return (
    <ListItem
      disablePadding
      component={disabled ? "div" : Link}
      to={!disabled ? path : undefined}
    >
      <ListItemButton disabled={disabled}>
        <ListItemIcon sx={{ color: color.primary_dark }}>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="caption" sx={{ color: color.primary_dark }}>
              {text}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

interface CollapseProps {
  text: string;
  icon: any;
  children: any;
}

export const DashboardMenuCollapse: FC<CollapseProps> = ({
  children,
  icon,
  text,
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((value) => !value);
  return (
    <ListItem disablePadding>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <ListItemButton onClick={toggleOpen}>
          <ListItemIcon sx={{ color: color.primary_dark }}>{icon}</ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="caption" sx={{ color: color.primary_dark }}>
                {text}
              </Typography>
            }
          />
        </ListItemButton>
        <Collapse in={open} style={{ paddingLeft: "1em" }}>
          {children}
        </Collapse>
      </div>
    </ListItem>
  );
};

type RoleType = {
  role: UserRole.USER | UserRole.EVALUATOR | UserRole.ADMIN | UserRole.FORM;
};

export const DashboardMenu: FC<RoleType> = ({ role }) => {
  const questionList = useLocation().state as any;
  return (
    <List>
      <DashboardMenuItem path="/" text="Home" icon={<Home />} />
      {role !== UserRole.FORM && (
        <>
          <DashboardMenuItem
            path="company"
            text="My Profile"
            icon={<AccountCircleIcon />}
          />
          <DashboardMenuItem
            path="/company/create"
            text="Create Company"
            icon={<BusinessIcon />}
          />
        </>
      )}

      <DashboardMenuItem
        path="/expert-form"
        text="Expert Specification Form"
        icon={<FormIcon />}
      />

      {(role === UserRole.ADMIN || role === UserRole.EVALUATOR) && (
        <DashboardMenuCollapse text="Audit" icon={<DynamicFormIcon />}>
          <DashboardMenuItem
            path="evaluation"
            text="Evaluation"
            icon={<Assessment />}
          />
          <DashboardMenuItem
            path="/digital-performance"
            text="Digital Performance "
            icon={<PerformanceIcon />}
          />
          <DashboardMenuItem
            path="/cert_category/"
            text="Create Question List"
            icon={<AddIcon />}
            disabled={!!questionList}
          />
          <DashboardMenuItem
            path="/question_list/"
            text="My Questions"
            icon={<HelpCenterIcon />}
          />
        </DashboardMenuCollapse>
      )}

      {role === UserRole.USER && (
        <DashboardMenuCollapse text="Audit" icon={<DynamicFormIcon />}>
          <DashboardMenuItem
            path="evaluation"
            text="Evaluation"
            icon={<Assessment />}
          />
          <DashboardMenuItem
            path="/digital-performance"
            text="Digital Performance "
            icon={<PerformanceIcon />}
          />
        </DashboardMenuCollapse>
      )}

      <hr />
      {role === UserRole.ADMIN && (
        <>
          <DashboardMenuItem path="users" text="Users" icon={<PeopleIcon />} />
          <DashboardMenuItem
            path="/cert_category/id"
            text="Create New Category"
            icon={<SubjectIcon />}
          />
        </>
      )}
    </List>
  );
};
