import {
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useUsers } from "../../Hooks/useUsers";
import color from "../../Constants/colors";
import { UserRole } from "../../Models/user";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ModelLevel } from "../../Models/user";

const UserPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: users } = useUsers(page, pageSize, "");

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangePerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(1);
  };

  const roleToString = (role: number) => {
    switch (role) {
      case UserRole.USER:
        return "User";
      case UserRole.ADMIN:
        return "Admin";
      case UserRole.EVALUATOR:
        return "Evaluator";
      case UserRole.FORM:
        return "Form";
      default:
        return "Unknown";
    }
  };

  const modelLevelToString = (level: number) => {
    switch (level) {
      case ModelLevel.BASIC:
        return "BASIC";
      case ModelLevel.ENTRY:
        return "ENTRY";
      case ModelLevel.FULL:
        return "FULL";
      default:
        return "Unknown";
    }
  };

  if (!users) {
    return <CircularProgress />;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      maxWidth="xl"
      margin="0 auto"
    >
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        mb={5}
        height="100px"
      >
        <Grid item>
          <Typography variant="h2" sx={{ color: color.white }}>
            Users
          </Typography>
        </Grid>
        <Grid item ml={3}>
          <Tooltip title="Create new user">
            <IconButton
              onClick={() => {
                navigate("create");
              }}
            >
              <AddCircleOutlineIcon
                sx={{ color: color.btn_dark, fontsize: "100px" }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              Surname
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              Email
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              Role
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              Companies Limit
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              Requests Limit
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              Model Level
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.users && users.users.length > 0 ? (
            users.users?.map((user) => (
              <TableRow
                key={user.id}
                sx={{
                  "&:hover": { backgroundColor: color.primary_dark },
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/user/${user.id}`)}
              >
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.surname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{roleToString(user.role)}</TableCell>
                <TableCell>{user.companies_limit}</TableCell>
                <TableCell>{user.daily_limit}</TableCell>
                <TableCell>{modelLevelToString(user.model_level)}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No users available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Grid item justifyContent="end" width="100%">
        <TablePagination
          rowsPerPageOptions={[1, 10, 25, 100]}
          component="div"
          count={users.total}
          rowsPerPage={pageSize}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePerPage}
          labelRowsPerPage="Elements per page"
        />
      </Grid>
    </Grid>
  );
};

export default UserPage;
