import { IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ChangeEvent } from "react";
import color from "../../Constants/colors";

interface QuestionListTextFieldProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  id?: number;
  onDelete?: (id: number) => void;
  label?: string;
  name?: string;
  required?: boolean;
  disabled: boolean;
  showDeleteButton?: boolean;
}

const QuestionListTextField: React.FC<QuestionListTextFieldProps> = ({
  value,
  onChange,
  onDelete,
  id,
  label = "Question",
  name = "question",
  required = true,
  showDeleteButton = false,
  disabled = false,
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      value={value}
      required={required}
      multiline
      onChange={onChange}
      disabled={disabled}
      InputProps={{
        style: { color: "#fff" },
        endAdornment: showDeleteButton && (
          <IconButton
            onClick={() => onDelete && id !== undefined && onDelete(id)}
          >
            <CloseIcon fontSize="large" sx={{ color: color.red }} />
          </IconButton>
        ),
      }}
      InputLabelProps={{
        style: { color: "white" },
      }}
    />
  );
};

export default QuestionListTextField;
