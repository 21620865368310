import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { ArrowForward } from "@mui/icons-material";
import color from "../../Constants/colors";

const FieldCardContainer = styled(Card)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  transition: "transform 0.3s",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  height: "100%",
  "&:hover": {
    transform: "scale(1.05)",
  },
  "&:hover::after": {
    transform: "translate(0, 0)",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    width: "200%",
    height: "200%",
    backgroundColor: color.btn_dark,
    transform: "translate(100%, -100%)",
    transition: "transform 0.8s ease-out, border-radius 0.8s ease-out",
    borderRadius: "8px",
    zIndex: 0,
  },
  "& h2, & p, & svg": {
    position: "relative",
    color: color.primary_dark,
    transition: "color 0.3s",
    zIndex: 1,
  },
  "&:hover h2, &:hover p, &:hover svg": {
    color: color.white,
  },
  borderRadius: "16px",
  [theme.breakpoints.down("sm")]: {
    minHeight: "250px",
  },
  [theme.breakpoints.up("md")]: {
    minHeight: "350px",
  },
}));

const CardActionAreaFull = styled(CardActionArea)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "inherit",
  textAlign: "center",
  padding: "16px",
});

const CardContentFull = styled(CardContent)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
});

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  borderRadius: "50%",
  transition: "transform 0.3s, color 0.3s",
  zIndex: 2,
  "&:hover": {
    color: color.primary_dark,
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  color: color.primary_dark,
  transition: "color 0.3s",
  zIndex: 1,
  "&:hover": {
    color: color.primary_dark,
  },
}));

interface FieldCardProps {
  title: string;
  description: string;
  hoverText: string;
  icon: React.ReactNode;
  link: string;
}

const FieldCard: React.FC<FieldCardProps> = ({
  title,
  description,
  hoverText,
  icon,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <FieldCardContainer onClick={() => navigate(link)}>
      <CardActionAreaFull>
        <CardContentFull>
          <IconContainer>{icon}</IconContainer>
          <Typography variant="h5" component="h2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" component="p">
            {description}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
              },
            }}
          >
            {hoverText}
          </Typography>
        </CardContentFull>
      </CardActionAreaFull>
      <ArrowButton onClick={() => navigate(link)}>
        <ArrowForward />
      </ArrowButton>
    </FieldCardContainer>
  );
};

export default FieldCard;
