import axios, { AxiosError } from "axios";

interface ErrorResponse {
  message: string;
}

const isErrorResponse = (data: any): data is ErrorResponse => {
  return typeof data === "object" && data !== null && "message" in data;
};

export const handleAxiosError = (error: unknown): string => {
  let errorMessage = "An unknown error occurred.";

  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      // Server responded with a status code outside the range of 2xx
      const responseBody = axiosError.response.data;
      if (isErrorResponse(responseBody)) {
        errorMessage = responseBody.message;
      } else {
        errorMessage =
          axiosError.response.statusText ||
          `Error: ${axiosError.response.status}`;
      }
    } else if (axiosError.request) {
      // Request was made but no response was received
      errorMessage =
        "No response received from the server. Please check your network connection.";
    } else {
      // Something happened in setting up the request
      errorMessage = axiosError.message;
    }
  } else if (error instanceof Error) {
    // Handle other types of errors (e.g., programming or runtime errors)
    errorMessage = error.message;
  }

  return errorMessage;
};
