import React from "react";
import { Grid, Paper, Typography, CircularProgress } from "@mui/material";
import { Document } from "../../Models/company";
import color from "../../Constants/colors";
import { ProcessingStatus } from "../../Models/user";
import { getFileIcon, truncateString } from "../../helpers/helpers";

interface FileItemProps {
  file: Document;
}

const UploadedFiles: React.FC<FileItemProps> = ({ file }) => {
  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 2,
      }}
      maxWidth={200}
    >
      <Paper
        variant="outlined"
        sx={{
          width: 80,
          height: 80,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          overflow: "hidden",
          "&:hover .remove-icon": {
            display: "flex",
          },
          opacity: file.status === ProcessingStatus.IN_PROGRESS ? 0.5 : 1,
          "& .MuiSvgIcon-root": {
            height: "100%",
            width: "100%",
            maxHeight: 100,
            maxWidth: 100,
          },
        }}
      >
        {getFileIcon(file.name)}
        {file.status === ProcessingStatus.IN_PROGRESS && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
              color: color.btn_dark,
            }}
          />
        )}
      </Paper>

      <Typography
        variant="caption"
        sx={{
          color: color.btn_dark,
          fontWeight: "bold",
          marginTop: "3px",
          textAlign: "center",
          maxWidth: 200,
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        title={file.name}
      >
        {truncateString(file.name, 18)}
      </Typography>
    </Grid>
  );
};

export default UploadedFiles;
