import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import color from "../../Constants/colors";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (inputValue: string) => void;
  categoryName: string;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  categoryName,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleConfirm = () => {
    if (inputValue === categoryName) {
      onConfirm(inputValue);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle id="delete">
        <Typography sx={{ color: color.white }}>Delete confirmation</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "white" }}>
          Are you sure you want to delete the category "{categoryName}" ?{" "}
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Deleting this category will delete all question lists for this
            category.
          </span>
          Please enter the category name to confirm.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Category Name"
          type="text"
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          No
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
