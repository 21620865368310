import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  createQuestion,
  createQuestionCategory,
  createQuestionList,
  fetchQuestionListDetails,
  fetchQuestionLists,
  fetchUserQuestionLists,
  toggleArchiveStatus,
  updateSingleQuestion,
} from "../Services/Https/questions";
import {
  QuestionListDetailsResponse,
  QuestionListResponse,
} from "../Models/question";

export const useQuestionListsforUser = (userId: number) => {
  return useQuery<QuestionListResponse, Error>(["questionLists", userId], () =>
    fetchUserQuestionLists(userId)
  );
};

export const useQuestionLists = () => {
  return useQuery<QuestionListResponse, Error>(["questionLists"], () =>
    fetchQuestionLists()
  );
};

export const useToggleArchiveStatus = () => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => toggleArchiveStatus(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("questionLists");
    },
  });
};

const useQuestionListDetails = (questionListId: unknown) => {
  return useQuery<QuestionListDetailsResponse, Error>(
    ["questionListDetails", questionListId],
    () => fetchQuestionListDetails(Number(questionListId)),
    { enabled: !!questionListId }
  );
};

export default useQuestionListDetails;

export const useCreateQuestionList = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: { name: string; category: string }) =>
      createQuestionList(params.name, params.category),
    {
      onSuccess: () => {
        // Invalidate and refetch the question lists query after mutation
        queryClient.invalidateQueries("questionLists");
      },
    }
  );
};

export const useCreateQuestionCategory = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: {
      name: string;
      questionCategoryKey: string;
      questionListId: number;
    }) =>
      createQuestionCategory(
        params.name,
        params.questionCategoryKey,
        params.questionListId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("questionLists");
      },
    }
  );
};

export const useCreateQuestion = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: {
      question: string;
      questionCategoryId: number;
      questionKey: string;
    }) =>
      createQuestion(
        params.question,
        params.questionCategoryId,
        params.questionKey
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("questionLists");
      },
    }
  );
};

export const useUpdateQuestion = () => {
  return useMutation(
    (params: { id: string | null; name: string; category?: number }) =>
      updateSingleQuestion(params.id, params.name, params.category)
  );
};
