import React from "react";
import ExpertForm from "../../Components/ExpertForm/ExpertForm";

const ExpertFormPage: React.FC = () => {
  return (
    <div>
      <ExpertForm />
    </div>
  );
};

export default ExpertFormPage;
