import { Typography } from "@mui/material";

interface LoaderProps {
  src: string;
  message?: string;
}

const Loader: React.FC<LoaderProps> = ({ src, message }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100px",
    }}
  >
    <img
      src={src}
      alt="Loading"
      className="fade-in"
      style={{
        width: "200px",
        position: "relative",
        borderRadius: "50%",
      }}
    />
    {message && (
      <Typography variant="body1" sx={{ textAlign: "center", color: "white" }}>
        {message}
      </Typography>
    )}
  </div>
);

export default Loader;
