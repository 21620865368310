import { useState, useEffect, useCallback, useMemo } from "react";
import { enqueueSnackbar } from "notistack";
import { QuestionListDetailsResponse } from "../Models/question";
import {
  fetchDefaultQuestionListDetails,
  fetchQuestionListDetails,
} from "../Services/Https/questions";
import { updateAboutCompany, uploadFile } from "../Services/Https/company";
import { useNavigate } from "react-router-dom";

export const useReviewQuestions = (
  questionListId: number | null,
  certificationId: number | null,
  companyId: string,
  initialAnswers: string
) => {
  const navigate = useNavigate();

  const [questionList, setQuestionList] =
    useState<QuestionListDetailsResponse>();

  const parsedInitialAnswers = useMemo(() => {
    if (!initialAnswers) {
      return {};
    }
    try {
      return JSON.parse(initialAnswers);
    } catch (error) {
      return {}; // Return an empty object or a default state if parsing fails.
    }
  }, [initialAnswers]);

  const [answers, setAnswers] = useState<{ [key: string]: string }>(
    parsedInitialAnswers
  );

  useEffect(() => {
    if (initialAnswers) {
      try {
        const parsedAnswers = JSON.parse(initialAnswers);
        setAnswers(parsedAnswers);
      } catch (error) {
        enqueueSnackbar("Error parsing initialAnswers", { variant: "error" });
      }
    }
  }, [initialAnswers]);

  const fetchQuestionList = useCallback(async () => {
    if (!questionListId && !certificationId) return;
    try {
      if (questionListId) {
        setQuestionList(await fetchQuestionListDetails(questionListId));
      } else if (certificationId) {
        setQuestionList(await fetchDefaultQuestionListDetails(certificationId));
      }
    } catch (err) {
      enqueueSnackbar("Error fetching question list", {
        variant: "error",
      });
    }
  }, [questionListId, certificationId]);

  const handleAnswerChange = (questionName: string, answer: string) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [questionName]: answer }));
  };

  useEffect(() => {
    fetchQuestionList();
  }, [fetchQuestionList]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    questionName: string
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("company_id", companyId);
      formData.append("document_name", file.name);
      formData.append("file", file);

      try {
        await uploadFile(formData);
        enqueueSnackbar("Successfully uploaded file", { variant: "success" });
      } catch (err) {
        enqueueSnackbar("Failed to upload file", { variant: "error" });
      }
    }
  };

  const handleSubmitUpdateAboutCompany = async () => {
    try {
      await updateAboutCompany(companyId, JSON.stringify(answers));
      enqueueSnackbar("Company Updated Successfully", {
        variant: "success",
      });
      navigate(`/company/${companyId}`);
    } catch (error) {
      enqueueSnackbar("Failed to Update Company", { variant: "error" });
    }
  };

  return {
    questionList,
    answers,
    setAnswers,
    handleAnswerChange,
    handleFileChange,
    handleSubmitUpdateAboutCompany,
  };
};
