import axios from "axios";
import { User } from "../../Models/user";

export const login = async (email: string, password: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/user/login/`,
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const checkAuth = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/user/check_auth/`,
    {
      withCredentials: true,
    }
  );
  return response;
};

export const checkAdmin = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/user/check_admin/`,
    {
      withCredentials: true,
    }
  );
  return response;
};

export const logout = async () => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/user/logout/`,
    {},
    {
      withCredentials: true,
    }
  );
  return response;
};

export const getUser = async (): Promise<User> => {
  const { data } = await axios.get<User>(
    `${process.env.REACT_APP_BASE_URL}/user/`,
    {
      withCredentials: true,
    }
  );
  return data;
};
