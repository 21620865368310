import axios from "axios";
import { ModelLevel, User, UserResponse } from "../../Models/user";

export const updateUser = async (
  name: string,
  surname: string,
  email: string
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/user/edit/`,
    {
      name: name,
      email: email,
      surname: surname,
    },
    {
      withCredentials: true,
    }
  );
  return response;
};

export const changePassword = async (
  currentPassword: string,
  newPassword: string
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/user/change-password/`,
    {
      current_password: currentPassword,
      new_password: newPassword,
    },
    {
      withCredentials: true,
    }
  );
  return response;
};

export const getUsers = async (
  page: number = 1,
  page_size: number = 10,
  search: string = ""
): Promise<UserResponse> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/user/users/`,
    {
      withCredentials: true,
      params: { page, page_size, search },
    }
  );
  return response.data;
};

export const createUser = async (
  name: string,
  surname: string,
  password: string,
  email: string,
  daily_limit: number,
  max_companies: number,
  role: number,
  model_level: ModelLevel,
  cert_category_ids: number[]
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/user/register/`,
    {
      name,
      surname,
      password,
      email,
      daily_limit,
      companies_limit: max_companies,
      role,
      model_level,
      cert_category_ids,
    },
    {
      withCredentials: true,
    }
  );
  return response;
};

export const getUserById = async (userId: string): Promise<User> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/user/${userId}/`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const editUserById = async (
  userId: string,
  name: string,
  surname: string,
  email: string,
  daily_limit: number,
  companies_limit: number,
  password: string,
  model_level: ModelLevel,
  cert_category_ids: number[]
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/user/${userId}/`,
    {
      name,
      surname,
      email,
      daily_limit,
      companies_limit,
      password,
      model_level,
      cert_category_ids,
    },
    {
      withCredentials: true,
    }
  );
  return response;
};

export const deleteUserById = async (userId: string) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/user/${userId}/`,
    {
      withCredentials: true,
    }
  );
  return response;
};
