import { useState } from "react";
import { useCompanies } from "../../Hooks/useCompanies";
import {
  CircularProgress,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import color from "../../Constants/colors";
import { statusToString } from "../../Services/Functions/statusToString";
import "../../Styles/Companies.css";
import { useNavigate } from "react-router-dom";
import { Company } from "../../Models/company";
const Companies = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<keyof Company>("name");
  const [search, setSearch] = useState("");
  const { data, isLoading } = useCompanies(
    page,
    pageSize,
    sortField,
    sortOrder,
    search
  );

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(1);
  };

  const handleSort = (field: keyof Company) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    const sortedData = data.companies.sort((a, b) => {
      const aValue = a[sortField] ?? "";
      const bValue = b[sortField] ?? "";

      if (aValue > bValue) {
        return sortOrder === "asc" ? 1 : -1;
      } else if (aValue < bValue) {
        return sortOrder === "asc" ? -1 : 1;
      } else {
        return 0;
      }
    });

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h2" sx={{ color: color.white }}>
            User Companies
          </Typography>
        </Grid>
        <Grid item mt={4}>
          <Input
            style={{
              color: "white",
              textAlign: "center",
            }}
            placeholder="Search Companies..."
            inputProps={{ "aria-label": "search" }}
            value={search}
            onChange={(event) => {
              setPage(1);
              setSearch(event.target.value);
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
        </Grid>
        <Grid item mt={5}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: color.btn_dark, fontWeight: "bolder" }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSort("name")}
                    sortDirection={sortField === "name" ? sortOrder : false}
                  >
                    <TableSortLabel
                      active={sortField === "name"}
                      direction={sortField === "name" ? sortOrder : "asc"}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSort("status")}
                    sortDirection={sortField === "status" ? sortOrder : false}
                  >
                    <TableSortLabel
                      active={sortField === "status"}
                      direction={sortField === "status" ? sortOrder : "asc"}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSort("grade")}
                    sortDirection={sortField === "grade" ? sortOrder : false}
                  >
                    <TableSortLabel
                      active={sortField === "grade"}
                      direction={sortField === "grade" ? sortOrder : "asc"}
                    >
                      Grade
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData && sortedData.length > 0 ? (
                  sortedData.map((company) => (
                    <TableRow
                      key={company.id}
                      onClick={() => navigate(`/company/${company.id}`)}
                      sx={{
                        "&:hover": { backgroundColor: color.primary_dark },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>{company.id}</TableCell>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>{statusToString(company.status)}</TableCell>
                      <TableCell>
                        {company.grade ? company.grade : ""}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="left">
                      No Companies available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item mb={10}>
          <TablePagination
            rowsPerPageOptions={[1, 10, 25, 100]}
            component="div"
            count={data.total}
            rowsPerPage={pageSize}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePerPage}
            labelRowsPerPage="Elements per page"
          />
        </Grid>
      </Grid>
    );
  }
};

export default Companies;
