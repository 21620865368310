import React from "react";
import { Grid } from "@mui/material";
import FileItem from "./FileItem";

interface FileListProps {
  files: File[];
  onRemoveFile: (fileName: string) => void;
  onOpenFile: (file: File) => void;
}

const FileList: React.FC<FileListProps> = ({
  files,
  onRemoveFile,
  onOpenFile,
}) => {
  return (
    <Grid container justifyContent="start" spacing={2} padding={2}>
      {files.map((file, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2.4}
          key={file.name}
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <FileItem file={file} onRemove={onRemoveFile} onOpen={onOpenFile} />
        </Grid>
      ))}
    </Grid>
  );
};

export default FileList;
