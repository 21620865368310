import axios from "axios";
import { ChatConfig } from "../../Models/quickwork";

export const fetchChatConfig = async (): Promise<ChatConfig> => {
  const { data } = await axios.get<ChatConfig>(
    `${process.env.REACT_APP_BASE_URL}/quickwork/token/`,
    {
      withCredentials: true,
    }
  );
  return data;
};
