import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import theme from "../../Constants/theme";
import CircleIcon from "@mui/icons-material/Circle";
import color from "../../Constants/colors";

interface EvaluationLegentProps {
  style?: React.CSSProperties;
}

const EvaluationLegent: React.FC<EvaluationLegentProps> = ({ style }) => {
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        position: isLargeScreen ? "fixed" : "relative",
        bottom: isLargeScreen ? "6%" : "auto",
        right: isLargeScreen ? "0%" : "auto",
        padding: "1%",
        borderRadius: 1,
        zIndex: 1000,
        width: isLargeScreen ? "20%" : "auto",
        mt: isLargeScreen ? 0 : 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style, // Accept style prop to set opacity
      }}
    >
      <Grid
        sx={{
          display: isLargeScreen ? "flex" : "block",
          flexDirection: isLargeScreen ? "column" : "row",
        }}
      >
        <Grid item container alignItems="center" spacing={1}>
          <Grid item>
            <CircleIcon
              sx={{
                color: color.green,
                fontSize: "1rem",
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                color: color.white,
                fontSize: "1rem",
              }}
            >
              Waiting for Evaluation
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" spacing={1}>
          <Grid item>
            <CircleIcon
              sx={{
                color: color.yellow,
                fontSize: "1rem",
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                color: color.white,
                fontSize: "1rem",
              }}
            >
              Need More Information
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" spacing={1}>
          <Grid item>
            <CircleIcon
              sx={{
                color: color.red,
                fontSize: "1rem",
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                color: color.white,
                fontSize: "1rem",
              }}
            >
              Evaluated
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EvaluationLegent;
