import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  textAlign: "center",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
}));

const DigitalPerformanceComponent: React.FC = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Digital Performance Page
      </Typography>
      <Typography variant="body1">
        This section is under construction. Please check back later for updates.
      </Typography>
    </Container>
  );
};

export default DigitalPerformanceComponent;
