import React, { useState } from "react";
import { CategoryDetail, EvaluationResponse } from "../../Models/evaluation";
import color from "../../Constants/colors";
import { PieChart } from "@mui/x-charts/PieChart";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Collapse,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "../../Styles/HomePage.css";
import EvaluationCategory from "../EvaluationCategory/EvaluationCategory";
import Questions from "../Questions/Questions";
import { QuestionListDetailsResponse } from "../../Models/question";

interface EvaluationProps {
  evaluationResponse: EvaluationResponse;
  questionList: QuestionListDetailsResponse | undefined;
  answers: { [key: string]: string };
  handleAnswerChange: (questionName: string, answer: string) => void;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    questionName: string
  ) => Promise<void>;
  handleSubmitUpdateAboutCompany: () => Promise<void>;
  resetAnswers: boolean;
  isQuestionFieldDisabled?: boolean;
  onAnswerChange?: (() => void) | undefined;
}

const Evaluation: React.FC<EvaluationProps> = ({
  evaluationResponse,
  questionList,
  answers,
  handleAnswerChange,
  handleFileChange,
  handleSubmitUpdateAboutCompany,
  resetAnswers,
  isQuestionFieldDisabled = false,
  onAnswerChange,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const summaryRating =
    typeof evaluationResponse.summary_rating === "number"
      ? evaluationResponse.summary_rating
      : 0;

  const filledPart = summaryRating;
  const emptyPart = 100 - filledPart;

  const categories = Object.keys(evaluationResponse).reduce((acc, key) => {
    const value = evaluationResponse[key as keyof EvaluationResponse];
    if (typeof value === "object" && value !== null && "summary" in value) {
      acc.push({ key, detail: value as CategoryDetail });
    }
    return acc;
  }, [] as { key: string; detail: CategoryDetail }[]);

  const goodCategories = categories.filter(
    (category) => category.detail.more_info === 0
  );

  const questionCategories = evaluationResponse.questions;

  const categoriesNeedingMoreInfo = categories.filter(
    (category) => category.detail.more_info === 1
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="80%"
      mb={10}
      sx={{ margin: "0 auto" }}
    >
      <Grid item mt={5}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h3" align="center" sx={{ color: color.white }}>
            Company Evaluation
          </Typography>
        </Grid>

        <div
          style={{
            position: "relative",
            display: "flex",
          }}
        >
          <PieChart
            series={[
              {
                data: [
                  { value: filledPart, color: color.btn_dark },
                  { value: emptyPart, color: color.white },
                ],
                innerRadius: 80,
                outerRadius: 99,
                paddingAngle: 0,
                cornerRadius: 5,
                startAngle: -90,
                endAngle: 90,
                cx: 150,
                cy: 150,
              },
            ]}
            width={300}
            height={150}
          />
          <div
            style={{
              position: "absolute",
              top: "90%",
              left: "52%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography variant="body1" sx={{ color: color.white }}>
              {summaryRating.toFixed(1)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item width="100%" mt={5}>
        <Typography
          variant="h4"
          align="center"
          sx={{ marginBottom: "18px", color: color.white }}
        >
          Company summary
        </Typography>
        <Typography variant="body1" sx={{ color: color.white }}>
          {evaluationResponse.summary}
        </Typography>
      </Grid>
      {goodCategories.map((category, index) => (
        <Grid
          item
          width="100%"
          key={category.key}
          style={{
            animation: `fadeInEffect 0.5s ease forwards ${0.7 * index}s`,
            opacity: 0,
          }}
        >
          <EvaluationCategory
            categoryDetail={category.detail}
            categoryKey={category.key}
          />
        </Grid>
      ))}
      {questionCategories.length > 0 && (
        <Grid item width="100%" mt={5}>
          <Typography
            variant="h4"
            align="center"
            sx={{
              marginBottom: "18px",
              color: color.white,
              textAlign: "center",
            }}
          >
            We had trouble gathering information for the following categories.
            Here are the questions:
          </Typography>
          {questionCategories.map((category) => (
            <div key={category.question_category_key}>
              <Typography
                variant="h5"
                sx={{
                  color: color.white,
                  marginTop: "50px",
                  textAlign: "center",
                  marginBottom: "-30px",
                }}
              >
                {category.question_category_name}
              </Typography>
              <Questions
                moreInfo={{
                  moreInfo: evaluationResponse.more_info,
                  questions: category.questions.map((q) => q.question_key),
                }}
                answers={answers}
                questionList={questionList}
                handleAnswerChange={handleAnswerChange}
                handleFileChange={handleFileChange}
                handleSubmitUpdateAboutCompany={handleSubmitUpdateAboutCompany}
                isQuestionFieldDisabled={isQuestionFieldDisabled}
                onAnswerChange={onAnswerChange}
                resetAnswers={resetAnswers}
              />
            </div>
          ))}
        </Grid>
      )}
      {categoriesNeedingMoreInfo.length > 0 && (
        <Grid item width="100%" mt={5}>
          <Tooltip
            title="Expand/Collapse evaluation segments that need more info"
            arrow
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: color.white,
                cursor: "pointer",
              }}
              onClick={handleExpandClick}
            >
              <Typography
                variant="h4"
                align="center"
                sx={{
                  marginBottom: "38px",
                  color: color.red,
                  marginLeft: "48px",
                  fontSize: "1.5rem",
                }}
              >
                {expanded
                  ? "Show Less"
                  : "Evaluation Segments That Need More Information"}
              </Typography>
              <IconButton
                sx={{ marginLeft: "14px", marginBottom: "38px" }}
                size="large"
              >
                {expanded ? (
                  <ExpandLessIcon sx={{ color: color.red }} />
                ) : (
                  <ExpandMoreIcon sx={{ color: color.red }} />
                )}
              </IconButton>
            </Box>
          </Tooltip>
          <Collapse in={expanded}>
            {categoriesNeedingMoreInfo.map((category) => (
              <Box
                key={category.key}
                sx={{
                  marginBottom: 2,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    border: `5px solid ${color.red}`,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    pointerEvents: "none",
                    zIndex: 1,
                  }}
                />
                <EvaluationCategory
                  categoryDetail={category.detail}
                  categoryKey={category.key}
                />
              </Box>
            ))}
          </Collapse>
        </Grid>
      )}
    </Grid>
  );
};

export default Evaluation;
