import { Button, Typography } from "@mui/material";
import { UserRole } from "../../Models/user";
import color from "../../Constants/colors";
import { enqueueSnackbar } from "notistack";

interface EvaluateButtonProps {
  handleEvaluateClick: (
    questionListId: number,
    certificationId: number
  ) => Promise<void>;
  selectedCompanyId: number | "";
  selectedModelId: number | "";
  certificationId: string;
  questionListId: string;
  currentUserRole: UserRole | undefined;
  isButtonClicked: boolean;
}

const EvaluateButton: React.FC<EvaluateButtonProps> = ({
  handleEvaluateClick,
  selectedCompanyId,
  selectedModelId,
  certificationId,
  questionListId,
  currentUserRole,
  isButtonClicked,
}) => {
  const isDisabled =
    !selectedCompanyId ||
    selectedModelId==="" ||
    !certificationId ||
    (!questionListId &&
      (currentUserRole === UserRole.ADMIN ||
        currentUserRole === UserRole.EVALUATOR));

  const handleClick = async () => {
    try {
      await handleEvaluateClick(
        parseInt(questionListId),
        parseInt(certificationId)
      );
    } catch (error: any) {
      const { message } = error.response?.data || error;
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  return (
    <Button
      sx={{
        backgroundColor: color.btn_dark,
        color: color.white,
        "&:hover": {
          backgroundColor: color.primary_dark,
          color: color.btn_dark,
        },
        marginBottom: 10,
        marginTop: 6,
        opacity: isDisabled ? "0.4" : "1",
      }}
      onClick={handleClick}
      disabled={isDisabled}
      className={isButtonClicked ? "fade-out" : ""}
    >
      <Typography variant="body1" sx={{ padding: 2 }}>
        Evaluate
      </Typography>
    </Button>
  );
};

export default EvaluateButton;
