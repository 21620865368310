import React from "react";
import { Grid, Paper, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import color from "../../Constants/colors";
import { getFileIcon } from "../../helpers/helpers";

interface FileItemProps {
  file: File;
  onRemove: (fileName: string) => void;
  onOpen: (file: File) => void;
}

const FileItem: React.FC<FileItemProps> = ({ file, onRemove, onOpen }) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          width: 70,
          height: 70,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          overflow: "hidden",
          "&:hover .remove-icon": {
            display: "flex",
          },
          "& .MuiSvgIcon-root": {
            height: "100%",
            width: "100%",
            maxHeight: 100,
            maxWidth: 100,
          },
        }}
        onClick={() => onOpen(file)}
      >
        {getFileIcon(file.name)}
        <span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onRemove(file.name);
            }}
            sx={{
              position: "absolute",
              top: "0px",
              right: "0px",
              padding: "2px",
              color: color.red,
              backgroundColor: "transparent",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: color.red,
                color: color.white,
              },
              transition: "background-color 0.2s, color 0.2s",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </span>
      </Paper>
      <Typography
        variant="caption"
        sx={{
          maxWidth: 200,
          wordWrap: "break-word",
          overflow: "hidden",
          color: color.btn_dark,
          fontWeight: "bold",
          textAlign: "center",
          paddingTop: 1,
        }}
      >
        {file.name}
      </Typography>
    </Grid>
  );
};

export default FileItem;
