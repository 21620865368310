import { Box, Grid, Typography } from "@mui/material";
import color from "../../Constants/colors";
import Circle from "./Circle";
import { CompanyStatus } from "../../Constants/Enums/companyStatus";
import InfoSection from "./InfoSection";
import Loader from "../UI/Loader";
import CheckIcon from '@mui/icons-material/Check';
import RotatingCachedIcon from "./RotatingCachedIcon";

interface EvaluationInProgressProps {
    companyName: string;
    questionListName: string;
    certificationCategoryName: string;
    modelName : string;
    companyStatus: CompanyStatus;
}

interface EvaluationProgress {
    id: CompanyStatus, 
    name: string
}

const evaluationProgress: EvaluationProgress[] = [
    { id: CompanyStatus.EVALUATION_IN_PROGRESS, name: "Evaluation request processing"},
    { id: CompanyStatus.FETCHING_COMPANY_DETAILS, name: "Reading company details"},
    { id: CompanyStatus.PROCESSING_COMPANY_INFORMATIONS, name: "Processing information"},
    { id: CompanyStatus.GRADING_COMPANY, name: "Grading company"},
];

const EvaluationInProgress: React.FC<EvaluationInProgressProps>  = ({
    companyName,
    questionListName,
    certificationCategoryName,
    modelName,
    companyStatus
}) => {

    const InfoSections = [
        { title: 'Company name:', value: companyName },
        { title: 'Model name:', value: modelName },
        { title: 'Certification category name:', value: certificationCategoryName },
        { title: 'Question list name:', value: questionListName },
      ];

    return (
        <Box>
            <Grid 
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                direction="column">
                <Grid item container
                display="flex"
                justifyContent="center"
                alignItems="center"
                direction="column">
                    <Grid item>
                        <Typography variant="h5" sx={{ color: color.white, marginTop: 1, marginBottom: 3 }}>
                            Evaluation in progress. You can leave the page, we will notify you
                            when it's done.
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center" sx={{marignTop: 1,marginBottom: 3}}>
                    {evaluationProgress.map((progress, index) => {
                        const isColored = progress.id <= companyStatus;
                        return (
                            <Grid item key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "12vw" }}>
                                <Circle colored={isColored} number={progress.id}>
                                    {companyStatus > progress.id ? <CheckIcon fontSize="large" /> : 
                                    (progress.id === companyStatus ? <RotatingCachedIcon fontSize="large" /> : 
                                    <Typography variant="h5" sx={{ color: color.white }}>{index + 1}</Typography>)}
                                </Circle>
                                <Typography variant="subtitle2" sx={{ color: color.white, textAlign: 'center', marginTop: 1, marginBottom: 1 }}>{progress.name}</Typography>
                            </Grid>
                        );
                    })}
                    </Grid>
                </Grid>
                <Grid container
    display="flex"
    justifyContent="center"
    alignItems="center"
    direction="column">
    <Grid container item spacing={2} justifyContent="center" alignItems="center" sx={{width : 1100}}>
        {InfoSections.map((item, index) => (
            <Grid item xs={6} key={index} display="flex" justifyContent="center">
                <InfoSection title={item.title} value={item.value} />
            </Grid>
        ))}
    </Grid>
    <Grid item>
        <Loader
            src="orange_loader.gif"
            message="Evaluation in progress..."
        />
    </Grid>
</Grid>
            </Grid>
        </Box>
    );
};

export default EvaluationInProgress;
