import { useQuery } from "react-query";
import {
  fetchCompanies,
  getCompanyWebsites,
  getSingleCompany,
  getCompanyStatus,
  checkProgress,
  fetchDocuments,
} from "../Services/Https/company";

export const useCompanies = (
  page: number,
  page_size: number,
  sort_order: string,
  sort_direction: string,
  search: string,
  isPoolingEnabled?: boolean
) => {
  return useQuery(
    ["companies", page, page_size, sort_order, sort_direction, search],
    () => fetchCompanies(page, page_size, sort_order, sort_direction, search),
    {
      refetchInterval: isPoolingEnabled ? 5000 : false,
    }
  );
};

export const useWebsites = (
  companyId: string,
  page: number,
  page_size: number,
  sort_order: string,
  sort_direction: string,
  search: string
) => {
  return useQuery(
    ["websites", page, page_size, sort_order, sort_direction, search],
    () =>
      getCompanyWebsites(
        companyId,
        page,
        page_size,
        sort_order,
        sort_direction,
        search
      ),
    {
      keepPreviousData: true,
      refetchInterval: 5000,
    }
  );
};

export const useDocuments = (
  companyId: string,
  page: number,
  page_size: number,
  sort_order: string,
  sort_direction: string
) => {
  return useQuery(
    ["documents", page, page_size, sort_order, sort_direction],
    () =>
      fetchDocuments(companyId, page, page_size, sort_order, sort_direction),
    { keepPreviousData: true, refetchInterval: 5000 }
  );
};

export const useCompany = (companyId: string) => {
  return useQuery(["company", companyId], () => getSingleCompany(companyId), {
    keepPreviousData: true,
    refetchInterval: 5000,
  });
};

export const useCompanyStatus = (companyId: string | undefined) => {
  return useQuery(
    ["companyStatus", companyId],
    () => {
      if (!companyId) {
        return Promise.reject(new Error("Invalid companyId"));
      }
      return getCompanyStatus(companyId);
    },
    {
      enabled: !!companyId,
      keepPreviousData: true,
      refetchInterval: 5000,
    }
  );
};

export const useCheckProgress = () => {
  return useQuery(["checkProgress"], () => checkProgress(), {
    keepPreviousData: true,
  });
};
