import axios from "axios";
import { EvaluationResponse, MoreInfoResponse } from "../../Models/evaluation";

export const fetchEvaluate = async (
  company_id: number,
  model_id: number,
  chunkSize: number,
  chunkOverlap: number,
  questionListId: number,
  certificate_id: number
): Promise<EvaluationResponse | MoreInfoResponse> => {
  try {
    const { data } = await axios.post<EvaluationResponse | MoreInfoResponse>(
      `${process.env.REACT_APP_BASE_URL}/llm/request_evaluation/`,
      {
        company_id: company_id,
        model_id: model_id,
        chunk_size: chunkSize,
        chunk_overlap: chunkOverlap,
        question_list_id: questionListId,
        certificate_id: certificate_id,
      },
      { withCredentials: true }
    );

    return data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      // Directly throw the error message from the backend
      throw new Error(error.response.data.error || "An error occurred.");
    } else if (error.request && error.request.responseText) {
      try {
        const parsedError = JSON.parse(error.request.responseText);
        throw new Error(parsedError.error || "An error occurred.");
      } catch (parseError) {
        throw new Error("An error occurred while parsing the response.");
      }
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};
