import React, { useEffect, useRef } from "react";
import { MoreInfoResponse } from "../../Models/evaluation";
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
  Input,
  Tooltip,
} from "@mui/material";
import { CheckCircle, AttachFile } from "@mui/icons-material";
import { QuestionListDetailsResponse } from "../../Models/question";

interface QuestionsProps {
  moreInfo: MoreInfoResponse;
  answers: { [key: string]: string };
  questionList: QuestionListDetailsResponse | undefined;
  handleAnswerChange: (questionName: string, answer: string) => void;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    questionName: string
  ) => Promise<void>;
  handleSubmitUpdateAboutCompany: () => Promise<void>;
  isQuestionFieldDisabled?: boolean;
  onAnswerChange?: (() => void) | undefined;
  resetAnswers?: boolean;
}

const Questions: React.FC<QuestionsProps> = ({
  moreInfo,
  questionList,
  answers,
  handleAnswerChange,
  handleFileChange,
  handleSubmitUpdateAboutCompany,
  isQuestionFieldDisabled = false,
  onAnswerChange,
  resetAnswers,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (resetAnswers) {
      Object.keys(answers).forEach((question) => {
        handleAnswerChange(question, answers[question] || "");
      });
    }
  }, [resetAnswers, answers, handleAnswerChange]);

  const isAnswered = (questionName: string) => !!answers[questionName];

  const findQuestionText = (questionKey: string) => {
    if (!questionList) return questionKey;
    for (const item of questionList.categories) {
      const foundQuestion = item.questions.find(
        (q) => q.question_key === questionKey
      );
      if (foundQuestion) {
        return foundQuestion.question;
      }
    }
    return questionKey;
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {moreInfo.questions.map((question: string, index: number) => {
        const questionText = findQuestionText(question);

        return (
          <Grid item width="100%" mt={5} key={index}>
            <Card sx={{ backgroundColor: "transparent" }}>
              <CardContent>
                <Typography variant="h6" color="white" mb={2}>
                  {questionText}
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ style: { color: "white" } }}
                    multiline
                    inputProps={{ style: { color: "white" } }}
                    label="Answer"
                    value={answers[question] || ""}
                    onChange={(e) => {
                      handleAnswerChange(question, e.target.value);
                      onAnswerChange && onAnswerChange();
                    }}
                    disabled={isQuestionFieldDisabled}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CheckCircle
                            style={{
                              color: isAnswered(question) ? "white" : "#e0e4fc",
                            }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {!isQuestionFieldDisabled && (
                            <Tooltip title="Attach a file">
                              <IconButton
                                color="primary"
                                onClick={() => fileInputRef.current?.click()}
                              >
                                <AttachFile />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Input
                            type="file"
                            inputRef={fileInputRef}
                            disabled={isQuestionFieldDisabled}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleFileChange(e, question)}
                            style={{ display: "none" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Questions;
