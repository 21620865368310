import React, { useEffect, useState } from "react";
import HomeComponent from "../../Components/Home/Home";
import { getUser } from "../../Services/Https/auth";
import { Grid, CircularProgress } from "@mui/material";
import { User } from "../../Models/user";
import { enqueueSnackbar } from "notistack";

const HomePage: React.FC = () => {
  const [userData, setUserData] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const user = await getUser();
        setUserData(user);
      } catch (error) {
        enqueueSnackbar("Error loading user data.", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchUser();
  }, []);

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <div>
      <HomeComponent currentUserRole={userData?.role} user={userData} />
    </div>
  );
};

export default HomePage;
