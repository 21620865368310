import React from "react";
import { Typography } from "@mui/material";
import FormTextField from "./FormTextField";
import colors from "../../Constants/colors";
import { ExpertFormState } from "../../Models/form";

interface SkillsAndAbilitiesProps {
  formState: ExpertFormState;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  validationErrors: any;
}

const SkillsAndAbilities: React.FC<SkillsAndAbilitiesProps> = ({
  formState,
  handleChange,
  validationErrors,
}) => {
  return (
    <>
      <Typography
        variant="h5"
        sx={{
          flexGrow: 1,
          color: colors.white,
          textAlign: "center",
          marginTop: "20px",
          marginLeft: {
            xs: "0",
            md: "30px",
          },
        }}
      >
        Skills and Abilities
      </Typography>
      <FormTextField
        label="Professional Background"
        name="professionalBackground"
        value={formState.professionalBackground}
        onChange={handleChange}
        info="Describe the expert's professional history and expertise relevant to their role. Example: 20 years in software development, former CTO at TechCorp."
        minRows={1}
        error={!!validationErrors.professionalBackground}
        helperText={validationErrors.professionalBackground}
      />
      <FormTextField
        label="Key Expertise"
        name="keyExpertise"
        value={formState.keyExpertise}
        onChange={handleChange}
        info="Detail the specific roles and capacities in which the expert has served. Example: AI, machine learning, data analysis."
        minRows={1}
        error={!!validationErrors.keyExpertise}
        helperText={validationErrors.keyExpertise}
      />
      <FormTextField
        label="Training and Certifications"
        name="trainingAndCertifications"
        value={formState.trainingAndCertifications}
        onChange={handleChange}
        info="List significant trainings or certifications the expert has completed. Example: Certified Scrum Master, Harvard Negotiation Training."
        minRows={1}
        error={!!validationErrors.trainingAndCertifications}
        helperText={validationErrors.trainingAndCertifications}
      />
      <FormTextField
        label="Process Mastery"
        name="processMastery"
        value={formState.processMastery}
        onChange={handleChange}
        info="Mention any specific methodologies or frameworks the expert excels in. Example: Agile, ITIL, Six Sigma."
        minRows={1}
        error={!!validationErrors.processMastery}
        helperText={validationErrors.processMastery}
      />
    </>
  );
};

export default SkillsAndAbilities;
