import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  FormHelperText,
} from "@mui/material";
import colors from "../../Constants/colors";
import InfoButton from "./InfoButton";

interface ModelBehaviorProps {
  value: string;
  handleChange: (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => void;
  error?: boolean;
  helperText?: string;
}

const ModelBehavior: React.FC<ModelBehaviorProps> = ({
  value,
  handleChange,
  error,
  helperText,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            flexGrow: 1,
            color: colors.white,
            marginBottom: "0",
            marginLeft: {
              xs: "0",
              md: "10px",
            },
          }}
        >
          Model Behavior
        </Typography>
        <InfoButton info="Choose 'Consistent' for consistent and predictable responses or 'Creative' for more varied and innovative responses." />
      </Box>
      <FormControl fullWidth variant="outlined" margin="normal" error={error}>
        <InputLabel style={{ color: colors.white }}>Model Behavior</InputLabel>
        <Select
          label="Model Behavior"
          name="modelBehavior"
          value={value}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: colors.primary_dark,
                opacity: 0.95,
              },
            },
          }}
          sx={{
            color: colors.white,
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: colors.white,
              },
              "&.Mui-focused fieldset": {
                borderColor: colors.white,
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: colors.white,
            },
          }}
        >
          <MenuItem sx={{ color: colors.white }} value="consistent">
            Consistent
          </MenuItem>
          <MenuItem sx={{ color: colors.white }} value="creative">
            Creative
          </MenuItem>
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default ModelBehavior;
