import { Link } from "react-router-dom";
import { Container, Typography } from "@mui/material";
function PageNotFound() {
  return (
    <>
      <Container sx={{ textAlign: "center", marginBottom: "50px" }}>
        <Typography sx={{ fontSize: "70px" }}>404 - Page Not Found</Typography>
        <br />
        <br />
        <Link to="/">
          <Typography sx={{ fontSize: "40px" }}>Go Back To Home</Typography>
        </Link>
      </Container>
    </>
  );
}

export default PageNotFound;
