import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./router";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

const Container = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if (initialLoad) {
      setTimeout(() => {
        setInitialLoad(false);
      }, 2000);
    }
  }, [initialLoad]);
  if (initialLoad) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          className="background-image-loading"
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          <Grid item>
            <img
              src="/logo_big_blue.png"
              alt="Logo"
              style={{
                width: "50vw",
                maxWidth: "350px",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item>
            <img
              src="/loaderElipsa.gif"
              alt="Loader"
              style={{
                width: "100%",
                maxWidth: "100px",
                height: "auto",
                marginTop: "-0.25rem",
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default Container;
