import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Button,
  Typography,
  TableSortLabel,
} from "@mui/material";
import { useWebsites } from "../../Hooks/useCompanies";
import { SetStateAction, useState, useRef, useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LanguageIcon from "@mui/icons-material/Language";
import {
  uploadWebsite,
  downloadScrappedWebsiteContent,
  deleteWebsite,
} from "../../Services/Https/company";
import { enqueueSnackbar } from "notistack";
import color from "../../Constants/colors";
import "../../Styles/Companies.css";
import { ProcessingStatus } from "../../Models/user";
import DownloadIcon from "@mui/icons-material/Download";
import colors from "../../Constants/colors";
import { Website } from "../../Models/website";
import React from "react";
import WebsiteTableRow from "./WebsiteTableRow";

interface WebsiteUploadProps {
  open: boolean;
  onClose: () => void;
  companyId: string;
}

const WebsiteUpload: React.FC<WebsiteUploadProps> = ({
  open,
  onClose,
  companyId,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [websitesResponse, setWebsitesResponse] = useState<any>(null);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);
  const { data: websitesData, isLoading } = useWebsites(
    companyId,
    page + 1,
    pageSize,
    sortField,
    sortOrder,
    ""
  );

  useEffect(() => {
    if (websitesData) {
      setWebsitesResponse(websitesData);
    }
  }, [websitesData]);

  const [isUploadingWebsites, setIsUploadingWebsites] = useState(false);
  const hasShownError = useRef(false);

  const [websiteName, setWebsiteName] = useState("");
  const [websiteAddress, setWebsiteAddress] = useState("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (field: SetStateAction<string>) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const handleWebsiteNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 25) {
      setWebsiteName(value);
      setIsSnackbarShown(false);
    } else {
      if (!isSnackbarShown) {
        enqueueSnackbar("Website name cannot exceed 25 characters.", {
          variant: "warning",
        });
        setIsSnackbarShown(true); // Set the flag to prevent multiple Snackbars
      }
    }
  };

  const handleUploadWebsite = async () => {
    try {
      setIsUploadingWebsites(true);
      const newWebsiteResponse = await uploadWebsite(
        companyId,
        websiteName,
        websiteAddress
      );

      const newWebsite = {
        id: newWebsiteResponse.data.id,
        name: websiteName,
        web_adress: websiteAddress,
        upload_date: new Date().toISOString(),
        status: ProcessingStatus.WAITING,
      };

      if (websitesResponse) {
        setWebsitesResponse({
          ...websitesResponse,
          websites: [newWebsite, ...websitesResponse.websites],
          total: websitesResponse.total + 1,
        });
      }

      setWebsiteName("");
      setWebsiteAddress("");
    } catch (error: any) {
      const { message } = error.response?.data || error;
      enqueueSnackbar(`Failed to upload website: ${message}`, {
        variant: "error",
      });
    } finally {
      setIsUploadingWebsites(false);
    }
  };

  const handleDeleteWebsite = async (website_id: number) => {
    try {
      await deleteWebsite(website_id);

      if (websitesResponse) {
        const updatedWebsites = websitesResponse.websites.filter(
          (website: Website) => website.id !== website_id
        );

        setWebsitesResponse({
          ...websitesResponse,
          websites: updatedWebsites,
          total: websitesResponse.total - 1,
        });
      }

      enqueueSnackbar("Website deleted successfully", { variant: "success" });
    } catch (error: any) {
      const { message } = error.response?.data || error;
      enqueueSnackbar(`Failed to delete website: ${message}`, {
        variant: "error",
      });
    }
  };

  const convertStatus = (status: number, websiteId: number) => {
    switch (status) {
      case ProcessingStatus.WAITING:
        return (
          <Typography variant="caption" sx={{ color: color.white }}>
            Processing...
          </Typography>
        );
      case ProcessingStatus.IN_PROGRESS:
        return (
          <Grid container direction="row" alignItems="center">
            <Typography variant="caption" sx={{ color: color.white }}>
              Processing...
            </Typography>
            <CircularProgress
              size={20}
              sx={{ marginLeft: "10px", color: color.white }}
            />
          </Grid>
        );
      case ProcessingStatus.PROCESSED:
        return (
          <Grid container direction="row" alignItems="center">
            <Typography variant="caption" sx={{ color: color.white }}>
              Done
            </Typography>
            <Tooltip title="Download scrapped data">
              <IconButton
                onClick={() => downloadScrappedWebsiteContent(websiteId)}
              >
                <DownloadIcon sx={{ color: color.btn_dark }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      default:
        if (!hasShownError.current) {
          enqueueSnackbar("Error while processing website", {
            variant: "error",
          });
          hasShownError.current = true;
        }
        return (
          <Typography variant="caption" sx={{ color: color.white }}>
            Error
          </Typography>
        );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          sx: {
            backgroundImage: "linear-gradient(#032e4d, #B1B1B3)",
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LanguageIcon sx={{ color: colors.btn_dark }} />
            <Typography variant="h4" sx={{ color: color.white }} ml={2}>
              Upload website
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            {isLoading || !websitesResponse ? (
              <CircularProgress />
            ) : (
              <>
                <Grid item width="100%">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: color.btn_dark,
                            cursor: "pointer",
                          }}
                          onClick={() => handleSort("name")}
                        >
                          <TableSortLabel
                            active={sortField === "name"}
                            direction={sortField === "name" ? sortOrder : "asc"}
                          >
                            Website
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: color.btn_dark,
                            cursor: "pointer",
                          }}
                          onClick={() => handleSort("status")}
                        >
                          <TableSortLabel
                            active={sortField === "status"}
                            direction={
                              sortField === "status" ? sortOrder : "asc"
                            }
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: color.btn_dark,
                            cursor: "pointer",
                          }}
                          onClick={() => handleSort("upload_date")}
                        >
                          <TableSortLabel
                            active={sortField === "upload_date"}
                            direction={
                              sortField === "upload_date" ? sortOrder : "asc"
                            }
                          >
                            Upload Date
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {websitesResponse.websites.map((website: Website) => (
                        <WebsiteTableRow
                          website={website}
                          onHandleDeleteWebsite={handleDeleteWebsite}
                          convertStatus={convertStatus}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <TablePagination
                    rowsPerPageOptions={[1, 10, 25, 50]}
                    component="div"
                    count={websitesResponse.total}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePerPage}
                    labelRowsPerPage="Websites per page"
                  />
                </Grid>
              </>
            )}

            <Grid item alignSelf="flex-start" mt={5}>
              <Typography variant="body1" sx={{ color: color.white }}>
                Create new website
              </Typography>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              alignItems="center"
              justifyContent="flex-start"
              mt={3}
            >
              <Grid item>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  value={websiteName}
                  onChange={handleWebsiteNameChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Web Address"
                  variant="outlined"
                  value={websiteAddress}
                  onChange={(e) => setWebsiteAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Tooltip
                  title={
                    !websiteName || !websiteAddress
                      ? "Please add website name and website address"
                      : "Upload website"
                  }
                >
                  <span>
                    <IconButton
                      color="primary"
                      onClick={handleUploadWebsite}
                      disabled={
                        isUploadingWebsites || !websiteName || !websiteAddress
                      }
                    >
                      <AddCircleOutlineIcon sx={{ color: color.btn_dark }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            variant="outlined"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WebsiteUpload;
