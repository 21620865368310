import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Certification } from "../Models/certification";
import { getCertCategories,getUserCategories, createCertCategory, editCertCategory, deleteCertCategory } from '../Services/Https/certifications';
import { UserRole } from '../Models/user';

export const useCertification = (currentUserRole: UserRole | undefined) => {
  return useQuery<Certification[], Error>(
    ["certifications"],
    () => currentUserRole === UserRole.ADMIN ? getCertCategories() : getUserCategories(),
    {
      keepPreviousData: true,
    }
  );
};

export const useCertifications = (currentUserRole: UserRole | undefined) => {
  return useQuery<Certification[], Error>(
    ["certifications"],
    () => currentUserRole === UserRole.ADMIN ? getCertCategories() : getUserCategories(),
    {
      keepPreviousData: true,
    }
  );
};

export const useCreateCertification = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (newCategory: Omit<Certification, 'id'>) => createCertCategory(newCategory),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('certifications');
      },
    }
  );
};

export const useUpdateCertification = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, updatedCategory }: { id: number; updatedCategory: Omit<Certification, 'id'> }) => editCertCategory(id, updatedCategory),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('certifications');
      },
    }
  );
};

export const useDeleteCertification = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (id: number) => deleteCertCategory(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('certifications');
      },
    }
  );
};


export const useAllCertifications = () => {
  return useQuery<Certification[], Error>(
    ["certifications"],
    () => getCertCategories(),
    {
      keepPreviousData: true,
    }
  );
};