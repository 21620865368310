import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import CachedIcon from '@mui/icons-material/Cached';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingCachedIcon = styled(CachedIcon)`
  animation: ${rotate} 2s linear infinite;
`;

export default RotatingCachedIcon;
