import axios from "axios";
import { FilteredContent } from "../../Models/company";

export const fetchFilteredContent = async (
  entity_type: string,
  entity_id: string,
  question_list_id: string | number
): Promise<FilteredContent> => {
  const { data } = await axios.get<FilteredContent>(
    `${process.env.REACT_APP_BASE_URL}/filtered_content/v2/${entity_type}/${entity_id}/?question_list_id=${question_list_id}`,
    {
      withCredentials: true,
    }
  );
  return data;
};
