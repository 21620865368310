import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReviewsIcon from "@mui/icons-material/Reviews";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Description from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import { CompanyInfo } from "../../Models/company";
import { generatePdf, generateWord } from "../../helpers/docsExtraction";
import { EvaluationResponse } from "../../Models/evaluation";
import FilteredContent from "./FilteredContent";
import DeleteConfirmationDialog from "./Dialogs/DeleteConfirmationDialog";
import WebsiteUpload from "../WebsiteUpload/WebsiteUpload";
import NestedMenuCustom from "../UI/NestedMenuCustom";
import { getTooltipTitle } from "../../helpers/helpers";

interface CompanyActionsProps {
  companyInfo: CompanyInfo;
  companyId: string;
  onUpdate: () => void;
  onDelete: () => void;
  onScrollToComponent: () => void;
  onOpenFileUploadModal: () => void;
  disablingEdit: boolean;
  visibility?: "visible" | "hidden";
}

const CompanyActions: React.FC<CompanyActionsProps> = ({
  companyInfo,
  companyId,
  onUpdate,
  onDelete,
  onScrollToComponent,
  onOpenFileUploadModal,
  disablingEdit,
}) => {
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [openFilteredContent, setOpenFilteredContent] = useState(false);
  const [websiteOpen, setWebsiteOpen] = useState<boolean>(false);

  const tooltipTitle = getTooltipTitle(companyInfo?.status);

  const handleDeleteConfirmationDialogOpen = () => {
    setDeleteConfirmationDialogOpen(true);
  };

  const handleDeleteConfirmationDialogClose = (
    confirmed: boolean,
    reason: string
  ) => {
    if (confirmed) {
      onDelete();
    } else {
      setDeleteConfirmationDialogOpen(false);
    }
  };

  const handleDeleteDialogClose = (event: any, reason: string) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      setDeleteConfirmationDialogOpen(false);
      return;
    }
    handleDeleteConfirmationDialogClose(false, reason);
  };

  const handleFilteredDialogOpen = () => {
    setOpenFilteredContent(true);
  };

  const handleFilteredDialogClose = () => {
    setOpenFilteredContent(false);
  };

  const handleWebsiteDialogOpen = () => {
    setWebsiteOpen(true);
  };

  const handleWebsiteDialogClose = () => {
    setWebsiteOpen(false);
  };

  const menuItemsActions = [
    {
      label: tooltipTitle,
      icon: ReviewsIcon,
      action: onScrollToComponent,
    },
    {
      label: "Export Documents",
      icon: Description,
      submenu: [
        ...(companyInfo.review && companyInfo.review.more_info === 0
          ? [
              {
                label: "Export Evaluation to PDF",
                icon: PictureAsPdf,
                action: () =>
                  generatePdf(
                    companyInfo.review as EvaluationResponse,
                    companyInfo.name
                  ),
              },
              {
                label: "Export Evaluation to Word",
                icon: Description,
                action: () =>
                  generateWord(
                    companyInfo.review as EvaluationResponse,
                    companyInfo.name
                  ),
              },
            ]
          : []),
      ],
      disabled: !(companyInfo.review && companyInfo.review.more_info === 0),
    },
    {
      label: "Edit Company Info",
      icon: EditIcon,
      action: onUpdate,
      disabled: !disablingEdit,
    },
    {
      label: "Delete Company",
      icon: DeleteIcon,
      action: handleDeleteConfirmationDialogOpen,
      disabled: deleteConfirmationDialogOpen,
    },
  ];

  const menuItemsView = [
    {
      label: "Open Filtered Content",
      icon: FilterAltIcon,
      action: handleFilteredDialogOpen,
    },
    {
      label: "File Management",
      icon: CloudUploadIcon,
      action: onOpenFileUploadModal,
    },
    {
      label: "Website Management",
      icon: OpenInBrowserIcon,
      action: handleWebsiteDialogOpen,
    },
  ];

  return (
    <Grid item>
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <NestedMenuCustom menuItems={menuItemsActions} name="Actions" />
        <NestedMenuCustom menuItems={menuItemsView} name="View" />
      </Box>

      <FilteredContent
        open={openFilteredContent}
        onClose={handleFilteredDialogClose}
        companyInfo={companyInfo}
      />
      <WebsiteUpload
        companyId={companyId}
        open={websiteOpen}
        onClose={handleWebsiteDialogClose}
      />
      <DeleteConfirmationDialog
        open={deleteConfirmationDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirmClose={handleDeleteConfirmationDialogClose}
      />
    </Grid>
  );
};

export default CompanyActions;
