import { ChangeEvent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useSnackbar } from "notistack";
import color from "../../Constants/colors";
import CloseIcon from "@mui/icons-material/Close";
import QuestionListTextField from "./QuestionListTextField";

interface SaveAndCancelButtonsProps {
  onSave: () => void;
  onCancel: () => void;
}

const SaveAndCancelButtons: React.FC<SaveAndCancelButtonsProps> = ({
  onSave,
  onCancel,
}) => {
  return (
    <Grid item>
      <IconButton onClick={onSave}>
        <CheckIcon fontSize="large" sx={{ color: color.orange }} />
      </IconButton>
      <IconButton onClick={onCancel}>
        <CloseIcon fontSize="large" sx={{ color: color.red }} />
      </IconButton>
    </Grid>
  );
};

const QuestionsForm = ({
  isEdit,
  categoryId,
  existingQuestions,
  setQuestionListFull,
}: any) => {
  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState<any>(existingQuestions || []);
  const [isAddingEnabled, setIsAddingEnabled] = useState<boolean>(false);
  const [enableButtonsOnChange, setEnableButtonsOnChange] =
    useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);

  const handleQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;

    if (inputText.length > 200) {
      if (!isSnackbarShown) {
        enqueueSnackbar(
          "Character limit exceeded! Maximum 200 characters allowed.",
          { variant: "warning" }
        );
        setIsSnackbarShown(true);
      }
      return;
    }

    setQuestion(inputText);
    setIsSnackbarShown(false);
  };

  const handleEditQuestionChange = (
    id: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputText = event.target.value;

    if (inputText.length > 200) {
      if (!isSnackbarShown) {
        enqueueSnackbar(
          "Character limit exceeded! Maximum 200 characters allowed.",
          { variant: "warning" }
        );
        setIsSnackbarShown(true);
      }
      return;
    }

    const updatedQuestions = questions.map((q: any) =>
      q.id === id ? { ...q, question: inputText } : q
    );

    setEnableButtonsOnChange(true);
    setQuestions(updatedQuestions);
    setIsSnackbarShown(false);
  };

  const handleSaveEditedQuestion = (updatedQuestions: any) => {
    setQuestionListFull((prevState: any) => {
      const updatedCategories = prevState.categories.map((category: any) =>
        category.id === categoryId
          ? { ...category, questions: updatedQuestions }
          : category
      );
      return { ...prevState, categories: updatedCategories };
    });
    setEnableButtonsOnChange(false);
  };

  const handleSaveQuestion = () => {
    if (question.trim() !== "" && question.length <= 200) {
      const newQuestion = {
        id: uuidv4(),
        question: question,
      };

      const updatedQuestions = [...questions, newQuestion];
      setQuestions(updatedQuestions);

      setQuestionListFull((prevState: any) => {
        const updatedCategories = prevState.categories.map((category: any) =>
          category.id === categoryId
            ? { ...category, questions: updatedQuestions }
            : category
        );
        return { ...prevState, categories: updatedCategories };
      });

      setQuestion("");
      setIsAddingEnabled(false);
    }
  };

  const handleDeleteQuestion = (questionId: number) => {
    const newQuestions = questions.filter(
      (question: any) => question.id !== questionId
    );
    setQuestions(newQuestions);

    setQuestionListFull((prevState: any) => {
      const updatedCategories = prevState.categories.map((category: any) =>
        category.id === categoryId
          ? { ...category, questions: newQuestions }
          : category
      );
      return { ...prevState, categories: updatedCategories };
    });
  };

  const handleEnableAdding = () => {
    setIsAddingEnabled(true);
  };

  const handleDisableAdding = () => {
    setIsAddingEnabled(false);
  };

  return (
    <Grid
      container
      display={"flex"}
      direction={"column"}
      paddingLeft={2}
      marginLeft={1}
      sx={{ borderLeft: 1, borderColor: color.white }}
    >
      <Grid container direction="row" spacing={2} padding={1}>
        {questions.map((question: any) =>
          !isEdit ? (
            <Grid item xs={12} key={question.id}>
              <QuestionListTextField
                value={question.question}
                onChange={() => {}}
                onDelete={() => handleDeleteQuestion(question.id)}
                showDeleteButton={true}
                disabled={true}
                id={question.id}
                key={question.id}
              />
            </Grid>
          ) : (
            <Grid item xs={12} key={question.id}>
              <QuestionListTextField
                value={question.question}
                onChange={(event) =>
                  handleEditQuestionChange(question.id, event)
                }
                onDelete={() => handleDeleteQuestion(question.id)}
                showDeleteButton={true}
                disabled={false}
                id={question.id}
                key={question.id}
              />
              {enableButtonsOnChange && (
                <SaveAndCancelButtons
                  onSave={() => handleSaveEditedQuestion(questions)}
                  onCancel={handleDisableAdding}
                />
              )}
            </Grid>
          )
        )}
      </Grid>

      {isAddingEnabled ? (
        <Grid item width="100%" padding={1}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Add Question"
                name="question"
                value={question}
                onChange={handleQuestionChange}
                required
                InputLabelProps={{
                  style: { color: "white" },
                }}
              />
            </Grid>
          </Grid>
          <SaveAndCancelButtons
            onSave={handleSaveQuestion}
            onCancel={handleDisableAdding}
          />
        </Grid>
      ) : (
        <Grid item padding={1}>
          <Button
            variant="contained"
            onClick={handleEnableAdding}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
          >
            Add Question
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default QuestionsForm;
