import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import color from '../../Constants/colors';

interface CircleProps {
    colored: boolean;
    number: number;
}

const Circle = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'colored',
  })<CircleProps>(({ theme, colored }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colored ? 'white' : color.orange,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: colored ? color.orange : 'transparent',
    borderRadius: '50%',
    width: '65px',
    height: '65px',
    marginTop: 1,
    marginBottom: 1
  }));

export default Circle;