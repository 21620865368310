import { useQuery } from "react-query";
import { getUsers } from "../Services/Https/user";

export const useUsers = (page: number, page_size: number, search: string) => {
  return useQuery(
    ["users", page, page_size, search],
    () => getUsers(page, page_size, search),
    { keepPreviousData: true }
  );
};
