import React from "react";
import { TextField, Grid, InputAdornment } from "@mui/material";
import InfoButton from "./InfoButton";
import colors from "../../Constants/colors";

interface FormTextFieldProps {
  label: string;
  name: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  info: string;
  minRows?: number;
  maxRows?: number;
  error?: boolean;
  helperText?: string;
}

const FormTextField: React.FC<FormTextFieldProps> = ({
  label,
  name,
  value,
  onChange,
  info,
  minRows,
  maxRows,
  error,
  helperText,
}) => {
  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        variant="outlined"
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        multiline
        minRows={minRows}
        maxRows={maxRows}
        margin="normal"
        error={error}
        helperText={helperText}
        InputLabelProps={{ style: { color: colors.white } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <InfoButton info={info} />
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            color: colors.white,
            "&:hover fieldset": {
              borderColor: colors.white,
            },
            "&.Mui-focused fieldset": {
              borderColor: colors.white,
            },
          },
          "& label.Mui-focused": {
            color: colors.white,
          },
        }}
      />
    </Grid>
  );
};

export default FormTextField;
