import { CircularProgress, Grid, TextField } from "@mui/material";
import useQuestionListDetails from "../../Hooks/useQuestions";
import { useParams } from "react-router-dom";
import color from "../../Constants/colors";

const SingleQuestionList = () => {
  const { listId } = useParams<{ listId: string }>();

  const { data, isError, isLoading } = useQuestionListDetails(listId);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return <div>Error: Failed to fetch Question List</div>;
  }

  return (
    <Grid width={"90%"} margin={"0 auto"} padding={"2rem 0"}>
      <TextField
        label="Question List Name"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={data?.name}
        disabled
        multiline
        InputLabelProps={{
          style: { color: "white" },
        }}
      />
      <Grid>
        {data?.categories.map((category: any) => (
          <Grid
            item
            key={category.id}
            sx={{
              border: 1,
              borderColor: color.orange,
              padding: 5,
              borderRadius: 1,
            }}
            marginBottom={2}
          >
            <TextField
              label="Question category"
              name="QuestionCategory"
              value={category.name}
              disabled
              fullWidth
              sx={{ marginBottom: 2 }}
              multiline
              InputLabelProps={{
                style: { color: "white" },
              }}
            />
            <Grid
              container
              display={"flex"}
              direction={"column"}
              paddingLeft={2}
              marginLeft={1}
              sx={{ borderLeft: 1, borderColor: color.white }}
            >
              {category.questions.map((question: any) => (
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  padding={1}
                  key={question.id}
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Question"
                      name="question"
                      value={question.question}
                      disabled
                      multiline
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default SingleQuestionList;
