import React, { useState } from "react";
import { Popover, IconButton, Typography, Box, Grow } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import colors from "../../Constants/colors";

interface InfoButtonProps {
  info: string;
}

const InfoButton: React.FC<InfoButtonProps> = ({ info }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <InfoIcon sx={{ color: colors.white }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 400 }}
        sx={{
          ".MuiPopover-paper": {
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            border: `1px solid ${colors.primary_medium}`,
            backgroundColor: colors.primary_not_present_in_design,
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            maxWidth: 300,
            bgcolor: colors.primary_not_present_in_design,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="body1" sx={{ color: colors.black }}>
            {info}
          </Typography>
        </Box>
      </Popover>
    </div>
  );
};

export default InfoButton;
